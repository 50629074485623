import React, { useCallback, useEffect } from "react";
import { ValidationError } from "common/resources/Resource";
import I18n from "../I18n";
import I18nStore from "../I18n/I18nStore";

type IFormContext = {
	getMessagesErrors: (name: string) => (string | undefined)[];
	getApiError: () => unknown;
};

type IProps = {
	children: React.ReactNode;
	errors: ValidationError[] | unknown;
};

export const FormContext = React.createContext<IFormContext>(undefined!);

function formatMessagesErrors(errors: unknown, name: string): (string | undefined)[] {
	if (!Array.isArray(errors)) return [];

	const segments = name.trim().split(".");

	let deepErrors = errors as ValidationError[];
	let segment = name;
	while (true) {
		if (!segments.length) break;

		let currentSegment: string | null;
		currentSegment = segments.shift() ?? null;
		if (!currentSegment) throw new Error("Invalid name");

		segment = currentSegment;

		if (!segments.length) break;

		deepErrors = deepErrors.find((error) => error.property === currentSegment)?.children ?? [];
	}

	const error = deepErrors.find((error) => error.property === segment);

	if (!error) return [];

	const messages: (string | undefined)[] = [];
	for (const key in error.constraints) {
		const translationKey =
			I18n.asset.enums.EValidationErrors[key as keyof typeof I18n.asset.enums.EValidationErrors] ??
			I18n.asset.enums.EValidationErrors[error.constraints[key] as keyof typeof I18n.asset.enums.EValidationErrors];
		if (!translationKey) {
			if (key === "error") {
				messages.push(error.constraints[key]);
				continue;
			}
			console.error("Missing translation key for error: ", key);
			messages.push("Erreur : " + key);
			continue;
		}

		const msg = I18nStore.getInstance().trslt(translationKey, {
			property: segment,
			var1: error.contexts?.[key]?.[0] ?? "",
			var2: error.contexts?.[key]?.[1] ?? "",
		});
		messages.push(msg);
	}

	return messages;
}

export function FormProvider({ errors, children }: IProps) {
	const getMessagesErrors = useCallback((name: string) => formatMessagesErrors(errors, name), [errors]);
	const getApiError = useCallback(() => errors, [errors]);

	useEffect(() => {
		if (errors && Array.isArray(errors) && errors.length > 0) {
			let property = errors[0].property ?? "";
			if (!errors[0].children) return;
			if (errors[0].children.length > 0) {
				property !== "" ? (property += `.${errors[0].children[0].property}`) : (property = errors[0].children[0].property);
			}
			let errorField = document.getElementsByName(property)[0] ?? null;
			if (!errorField || errorField.hidden === true) errorField = document.getElementById(property);
			if (!errorField) return;
			errorField.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [errors]);
	return (
		<FormContext.Provider
			value={{
				getMessagesErrors,
				getApiError,
			}}>
			{children}
		</FormContext.Provider>
	);
}
