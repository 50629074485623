import AuthPageTemplate from "components/PageTemplate/AuthPageTemplate";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import { useCallback, useState } from "react";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import { useNavigate } from "react-router-dom";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import Input from "components/materials/Form/InputElement";
import Form from "components/materials/Form";
import { ValidationError } from "common/resources/Resource";
import UserLoginRequestResource from "common/resources/User/UserLoginRequestResource";
import AuthService from "services/AuthService";

const modules = container.resolve(ModuleConfig).get().modules;
const authService = container.resolve(AuthService);

export default function Login() {
	const navigate = useNavigate();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const redirectToHomePage = useCallback(() => {
		navigate(modules.pages.Home.props.path);
	}, [navigate]);

	const redirectAfterLogin = useCallback(() => {
		const redirectUrl = new URLSearchParams(window.location.search).get("redirect");
		if (redirectUrl) {
			navigate(redirectUrl, { replace: true });
		} else {
			redirectToHomePage();
		}
	}, [redirectToHomePage, navigate]);

	const redirectToForgotPasswordPage = useCallback(() => {
		navigate(modules.pages.ForgotPassword.props.path);
	}, [navigate]);

	const redirectToRegisterPage = useCallback(() => {
		navigate(modules.pages.Register.props.path);
	}, [navigate]);

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);
			const userRequest = UserLoginRequestResource.hydrate<UserLoginRequestResource>({
				email: formData["email"] as string,
				password: formData["password"] as string,
			});

			userRequest
				.validateOrReject()
				.then(() => {
					authService
						.login(userRequest)
						.then(() => {
							redirectAfterLogin();
						})
						.catch((error) => {
							console.error({ error });
							if (error instanceof Array) {
								setErrors(error);
							}
						});
				})
				.catch((error) => {
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[redirectAfterLogin],
	);

	return (
		<AuthPageTemplate tabTitle={I18n.trslt(I18n.asset.pages.login.page_title)}>
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<Typography type="h1" weight="bold">
						{I18n.trslt(I18n.asset.pages.login.title)}
					</Typography>
					<Typography type="p" weight="regular" size="large">
						{I18n.trslt(I18n.asset.pages.login.subtitle)}
					</Typography>
				</div>

				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<Input name="email" label={I18n.trslt(I18n.asset.pages.login.labels.email)} className={classes["input"]} />

					<div className={classes["input-container"]}>
						<Input name="password" label={I18n.trslt(I18n.asset.pages.login.labels.password)} type="password" className={classes["input"]} />
						<Button variant={EButtonVariant.TEXT} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM} onClick={redirectToForgotPasswordPage}>
							{I18n.trslt(I18n.asset.pages.login.password_forgotten)}
						</Button>
					</div>

					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} fullwidth type="submit">
						{I18n.trslt(I18n.asset.pages.login.login_button)}
					</Button>

					<div className={classes["register-redirect-container"]}>
						<Typography type="p" weight="regular" size="medium">
							{I18n.trslt(I18n.asset.pages.login.redirect_text)}
						</Typography>
						<Button variant={EButtonVariant.TEXT} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM} onClick={redirectToRegisterPage}>
							{I18n.trslt(I18n.asset.pages.login.redirect_link)}
						</Button>
					</div>
				</Form>
			</div>
		</AuthPageTemplate>
	);
}
