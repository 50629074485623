import Button, { EButtonVariant, EButtonColor, EButtonSize } from "components/elements/Button";
import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import StatusIcon from "components/materials/StatusIcon";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import Chip from "components/elements/Chip";
import { EllipsisHorizontalIcon, EyeIcon, ArrowDownTrayIcon } from "@heroicons/react/20/solid";

const modules = container.resolve(ModuleConfig).get().modules;

type IProps = {
	projectId: string;
	projectName: string;
	projectStatus: EProjectStatus;
	hasPoster: boolean;
	hasAnchorProof: boolean;
	anchorProofLink?: string;
	date: string;
	onViewImageClick: () => void;
};

export default function Row(props: IProps) {
	const date = () => {
		return (
			<Typography type="span" size="medium" weight="regular">
				{new Date(props.date).toLocaleDateString("fr-FR", { day: "numeric", month: "long", year: "numeric" })}
			</Typography>
		);
	};

	return (
		<tr className={classes["root"]}>
			<td className={classNames(classes["td-name"])}>
				<Link to={modules.pages.Project.props.path.replace(":id", props.projectId)} className={classes["link"]}>
					<Typography className={classes["ellipsis"]}>{props.projectName}</Typography>
				</Link>
				<div className={classes["responsive"]}>
					<StatusIcon status={props.projectStatus} />
					<Chip>{date()}</Chip>
				</div>
			</td>

			<td className={classes["td-ai-image"]}>
				{props.hasPoster && (
					<Button onClick={props.onViewImageClick} variant={EButtonVariant.SELECTOR} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM}>
						<EyeIcon className={classes["icons"]} />
					</Button>
				)}
				{!props.hasPoster && <EllipsisHorizontalIcon className={classes["icons"]} />}
			</td>

			<td className={classes["td-proof"]}>
				{props.hasAnchorProof && props.anchorProofLink && (
					<Link to={props.anchorProofLink} target="_blank" rel="noreferrer">
						<ArrowDownTrayIcon className={classes["icons"]} />
					</Link>
				)}
				{(!props.hasAnchorProof || !props.anchorProofLink) && <EllipsisHorizontalIcon className={classes["icons"]} />}
			</td>

			<td className={classes["td-status"]}>
				<StatusIcon status={props.projectStatus} />
			</td>

			<td className={classes["td-date"]}>{date()}</td>
		</tr>
	);
}
