import classes from "./classes.module.scss";
import Typography from "../Typography";
import classNames from "classnames";
import { useCallback, useRef } from "react";

type IProps = {
	checked: boolean;
	uid: string;
	label: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
};

export default function Checkbox(props: IProps) {
	const checkboxRef = useRef<HTMLInputElement>(null);

	const onCheckboxClick = useCallback(() => {
		if (!checkboxRef.current) return;
		checkboxRef.current.click();
	}, [checkboxRef]);

	const renderIcon = () => {
		if (props.checked) {
			return "✓"; // Checked icon
		}
		return ""; // Unchecked has no icon
	};

	return (
		<div className={classNames(classes["root"], props.className)}>
			<div className={classes["checkboxes-container"]}>
				<input ref={checkboxRef} id={props.uid} type="checkbox" checked={props.checked} onChange={props.onChange} className={classes["original-checkbox"]} />
				<div
					onClick={onCheckboxClick}
					className={classNames(classes["custom-checkbox"], {
						[classes["checked"]!]: props.checked,
					})}>
					{renderIcon()}
				</div>
			</div>

			<label htmlFor={props.uid} className={classes["label"]}>
				<Typography type="span" weight="regular" size="medium" fontFamily="secondary">
					{props.label}
				</Typography>
			</label>
		</div>
	);
}
