import ProjectApi from "api/clapaction/admin/ProjectApi";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import ProjectUpdateStatusRequestResource from "common/resources/Project/ProjectUpdateStatusRequestResource";
import { container, singleton } from "tsyringe";
import { Pagination } from "utils/Pagination";
import toValidationError from "utils/toValidationError";

@singleton()
export default class ProjectService {
	private api: ProjectApi = container.resolve(ProjectApi);

	public async getProjects(pagination?: Pagination): Promise<ProjectResponseResource[]> {
		return this.api.getProjects(pagination).catch(toValidationError);
	}

	public async countProjects(): Promise<number> {
		return this.api.countProjects().then((res) => res.total).catch(toValidationError);
	}

	public async getProjectByStatus(status: EProjectStatus, pagination?: Pagination): Promise<ProjectResponseResource[]> {
		return this.api.getProjectByStatus(status, pagination).catch(toValidationError);
	}

	public async countProjectsByStatus(status: EProjectStatus): Promise<number> {
		return this.api.countProjectsByStatus(status).then((res) => res.total).catch(toValidationError);
	}

	public async getArchivedProjects(pagination?: Pagination): Promise<ProjectResponseResource[]> {
		return this.api.getArchivedProjects(pagination).catch(toValidationError);
	}

	public async countArchivedProjects(): Promise<number> {
		return this.api.countArchivedProjects().then((res) => res.total).catch(toValidationError);
	}

	public async updateStatus(projectId: string, project: ProjectUpdateStatusRequestResource): Promise<ProjectResponseResource> {
		return this.api.updateStatus(projectId, project).catch(toValidationError);
	}

	public async archive(projectId: string): Promise<ProjectResponseResource> {
		return this.api.archive(projectId).catch(toValidationError);
	}

	public async restore(projectId: string): Promise<ProjectResponseResource> {
		return this.api.restore(projectId).catch(toValidationError);
	}
}
