import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import I18n from "../I18n";
import { Themes } from "./Theme/Themes";
import Theme from "./Theme";
import { EProjectTheme } from "common/enums/ProjectTheme/ProjectTheme";

type IProps = {
	onSelect: (theme: EProjectTheme) => void;
	selectedTheme: string;
};

export default function ThemeSelector(props: IProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["title-container"]}>
				<Typography type="p" weight="regular" size="medium">
					{I18n.trslt(I18n.asset.component.theme_selector.title)}
				</Typography>

				<Typography type="p" weight="regular" size="medium">
					{I18n.trslt(I18n.asset.component.theme_selector.select)}
				</Typography>
			</div>

			<div className={classes["themes"]}>
				{Themes.map((theme) => (
					<Theme key={theme.value} label={theme.label} isSelected={props.selectedTheme === theme.value} onClick={() => props.onSelect(theme.value)} />
				))}
			</div>
		</div>
	);
}
