import { useCallback, useContext, useEffect, useState } from "react";
import classes from "./classes.module.scss";
import { FormContext } from "../FormContext";
import classNames from "classnames";
import Typography from "components/elements/Typography";

type IProps = {
	name: string;
	options: { label: string; value: string }[];
	label?: string;
	required?: boolean;
	additionalInfo?: string;
	onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	className?: string;
	defaultValue?: string;
};

export default function Select(props: IProps) {
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(props.name);
	const hasErrors = errors?.length > 0;
	const [value, setValue] = useState(props.defaultValue || "");

	const onWheel = useCallback((event: React.WheelEvent<HTMLSelectElement>) => {
		(event.target as HTMLInputElement).blur();
	}, []);

	// No useCallBack here because the only dependency is props.onChange
	const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const inputValue = event.target.value;
		let valueToSet = inputValue;

		setValue(valueToSet);
		event.target.value = valueToSet;
		props.onChange && props.onChange(event);
	};

	useEffect(() => {
		setValue(props.defaultValue || "");
	}, [props.defaultValue]);

	return (
		<div className={classNames(classes["root"], props.className)}>
			{props.label && (
				<label htmlFor={props.name} className={classes["label"]}>
					<Typography type="span" weight="regular" size="medium">
						{props.label}
						{props.required && "*"}
					</Typography>
				</label>
			)}

			<select id={props.name} onWheel={onWheel} name={props.name} required={props.required} value={value} onChange={handleInputChange} className={classes["select"]}>
				{props.options.map((option) => (
					<option key={option.value} value={option.value} className={classes["option"]}>
						{option.label}
					</option>
				))}
			</select>

			{props.additionalInfo && (
				<Typography type="span" weight="regular" size="small" fontFamily="secondary" className={classes["additional-info"]}>
					{props.additionalInfo}
				</Typography>
			)}

			{hasErrors && (
				<div className={classes["errors-container"]}>
					{errors.map((message, i) => (
						<Typography type="p" size="small" weight="medium" key={i} color="error">
							{message}
						</Typography>
					))}
				</div>
			)}
		</div>
	);
}
