import { useState } from "react";
import classes from "./classes.module.scss";

type IProps = {
	src: File | string | null;
	alt: string;
	className?: string;
};

export default function LazyImage(props: IProps) {
	const [src, setSrc] = useState<File | string | null>(null);
	const onePixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBkZAAAAAoAAx9k7/gAAAAASUVORK5CYII=";

	const img = new Image();
	img.onload = () => setSrc(props.src);
	img.src = props.src as string;

	if (src) {
		return <img src={src as string} alt={props.alt} className={classes["fade-in"]!.concat(" ", props.className ?? "")} />;
	}
	return <img src={onePixel} alt={props.alt} className={props.className} />;
}
