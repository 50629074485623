import classNames from "classnames";
import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onClose?: () => void;
	children?: React.ReactNode;
	className?: string;
};

export default function Modal(props: IProps) {
	if (!props.isOpen) return null;
	return (
		<div className={classes["root"]}>
			<div className={classes["backdrop"]} onClick={props.onClose} />
			<div className={classNames(classes["modal"], props.className)}>{props.children}</div>
		</div>
	);
}
