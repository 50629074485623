import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { useCallback, useContext } from "react";
import { FormContext } from "../FormContext";

type IProps = {
	name: string;
	value: string;
	label?: string;
	additionalInfo?: string;
	required?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
};

export default function Radio(props: IProps) {
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(props.name);
	const hasErrors = errors?.length > 0;

	const onWheel = useCallback((event: React.WheelEvent<HTMLInputElement>) => {
		(event.target as HTMLInputElement).blur();
	}, []);

	return (
		<div className={classNames(classes["root"], props.className)}>
			<input
				id={props.value}
				onWheel={onWheel}
				name={props.name}
				type={"radio"}
				required={props.required}
				value={props.value}
				onChange={props.onChange}
				className={classes["input"]}
			/>
			{props.label && (
				<label htmlFor={props.value} className={classes["label"]}>
					<Typography type="span" weight="regular" size="medium">
						{props.label}
						{props.required && "*"}
					</Typography>
				</label>
			)}
			{hasErrors && (
				<div className={classes["errors-container"]}>
					{errors.map((message, i) => (
						<Typography type="p" size="small" weight="medium" key={i} color="error">
							{message}
						</Typography>
					))}
				</div>
			)}
		</div>
	);
}
