import { singleton } from "tsyringe";
import BaseAdminApi from "./BaseAdminApi";

@singleton()
export default class FileApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/files`;

	public getFileUrl(folder: string, fileId: string): string {
		return `${this.baseurl}/${folder}/${fileId}`;
	}
}
