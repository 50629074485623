import Button, { EButtonVariant, EButtonColor, EButtonSize } from "components/elements/Button";
import classes from "./classes.module.scss";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classNames from "classnames";

const modules = container.resolve(ModuleConfig).get().modules;

type IProps = {
	className?: string;
};

export default function NotConnectedHeader(props: IProps) {
	const navigate = useNavigate();

	const redirectToLoginPage = useCallback(() => {
		navigate(modules.pages.Login.props.path);
	}, [navigate]);

	const redirectToRegisterPage = useCallback(() => {
		navigate(modules.pages.Register.props.path);
	}, [navigate]);

	return (
		<div className={classNames(classes["root"], props.className)}>
			<Button onClick={redirectToLoginPage} variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM} fullwidth>
				<Typography type="span" size="medium" weight="medium">
					{I18n.trslt(I18n.asset.component.header.login)}
				</Typography>
			</Button>

			<Button onClick={redirectToRegisterPage} variant={EButtonVariant.OUTLINED} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM} fullwidth>
				<Typography type="span" size="medium" weight="medium">
					{I18n.trslt(I18n.asset.component.header.register)}
				</Typography>
			</Button>
		</div>
	);
}
