import classNames from "classnames";
import classes from "./classes.module.scss";

type IProps = {
	icon: JSX.Element;
	onClick?: () => void;
	className?: string;
};

export default function IconButton(props: IProps) {
	return (
		<div onClick={props.onClick} className={classNames(classes["root"], props.className)}>
			{props.icon}
		</div>
	);
}
