import { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";

type IProps = PropsWithChildren;

export default function ScrollToTop(props: IProps) {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

		if (location.hash) {
			setTimeout(() => {
				const element = document.querySelector(location.hash);
				if (element) {
					element.scrollIntoView({ behavior: "smooth", block: "start" });
				}
			}, 300);
		}
	}, [location]);

	return <>{props.children}</>;
}
