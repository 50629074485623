import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import classNames from "classnames";

type IProps = {
	label: string;
	isSelected: boolean;
	onClick: (label: string) => void;
};

export default function Theme(props: IProps) {
	return (
		<div
			onClick={() => props.onClick(props.label)}
			className={classNames(classes["root"], {
				[classes["selected"]!]: props.isSelected,
			})}>
			<Typography type="p" size="small" weight="regular">
				{props.label}
			</Typography>
		</div>
	);
}
