import { Link } from "react-router-dom";
import classes from "./classes.module.scss";
import ClapActionLogo from "assets/images/logo-clapaction.png";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import Typography from "components/elements/Typography";
import { useContext } from "react";
import { UserContext } from "contexts/User";
import NotConnectedHeader from "./NotConnectedHeader";
import ConnectedHeader from "./ConnectedHeader";
import I18n from "../I18n";
import ResponsiveMenu from "./ResponsiveMenu";
import LangSelector from "./LangSelector";

const modules = container.resolve(ModuleConfig).get().modules;

export default function Header() {
	const { user } = useContext(UserContext);

	return (
		<header className={classes["root"]}>
			<nav className={classes["content"]}>
				<Link to={modules.pages.Home.props.path} className={classes["logo-container"]}>
					<img src={ClapActionLogo} alt="clapaction logo" className={classes["logo"]} />
				</Link>

				<div className={classes["links-container"]}>
					<Link to={modules.pages.Home.props.path} className={classes["link"]}>
						<Typography type="span" size="medium" weight="medium">
							{I18n.trslt(I18n.asset.component.header.home)}
						</Typography>
					</Link>

					<Link to={modules.pages.Home.props.path.concat("#marketplace")} className={classes["link"]}>
						<Typography type="span" size="medium" weight="medium">
							{I18n.trslt(I18n.asset.component.header.marketplace)}
						</Typography>
					</Link>

					<Link to={modules.pages.Projects.props.path} className={classes["link"]}>
						<Typography type="span" size="medium" weight="medium">
							{I18n.trslt(I18n.asset.component.header.projects)}
						</Typography>
					</Link>

					<Link to={modules.pages.Subscribe.props.path} className={classes["link"]}>
						<Typography type="span" size="medium" weight="medium">
							{I18n.trslt(I18n.asset.component.header.subscribe)}
						</Typography>
					</Link>

					<Link to={modules.pages.Contact.props.path} className={classes["link"]}>
						<Typography type="span" size="medium" weight="medium">
							{I18n.trslt(I18n.asset.component.header.contact)}
						</Typography>
					</Link>

					<LangSelector />

					<div className={classes["extra-header"]}>
						{!user && <NotConnectedHeader />}
						{user && <ConnectedHeader user={user} />}
					</div>

					<ResponsiveMenu user={user} />
				</div>
			</nav>
		</header>
	);
}
