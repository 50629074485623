import PageTemplate from "components/PageTemplate";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import Typography from "components/elements/Typography";
import Form from "components/materials/Form";
import { ValidationError } from "common/resources/Resource";
import { useCallback, useState } from "react";
import Input from "components/materials/Form/InputElement";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import TextArea from "components/materials/Form/TextAreaElement";
import EllipsisImage from "assets/images/ellipsis-design.png";
import ContactUsRequestResource from "common/resources/Form/ContactUsRequestResource";
import { container } from "tsyringe";
import FormService from "services/FormService";
import ModuleConfig from "configs/ModuleConfig";
import { useNavigate } from "react-router-dom";

const formService = container.resolve(FormService);
const modules = container.resolve(ModuleConfig).get().modules;

export default function Contact() {
	const navigate = useNavigate();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);
			const contactRequest = ContactUsRequestResource.hydrate<ContactUsRequestResource>({
				email: formData["email"] as string,
				message: formData["message"] as string,
				subject: formData["subject"] as string,
				firstname: formData["firstname"] as string,
				lastname: formData["lastname"] as string,
				lang: I18n.getLang(),
			});

			contactRequest
				.validateOrReject()
				.then(() => {
					formService.contactUs(contactRequest).then(() => {
						alert(I18n.trslt(I18n.asset.pages.contact.confirmation));
						navigate(modules.pages.Home.props.path);
					});
				})
				.catch((error) => {
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[navigate],
	);

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.contact.page_title)} mainSectionClassName={classes["root"]}>
			<div className={classes["content"]}>
				<div className={classes["title-container"]}>
					<Typography type="h1" weight="bold">
						{I18n.trslt(I18n.asset.pages.contact.title)}
					</Typography>
					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.contact.subtitle)}
					</Typography>
				</div>

				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<div className={classes["input-container"]}>
						<div className={classes["inputs"]}>
							<Input placeholder="John" name="firstname" label={I18n.trslt(I18n.asset.pages.contact.labels.firstname)} required className={classes["input"]} />
							<Input placeholder="Doe" name="lastname" label={I18n.trslt(I18n.asset.pages.contact.labels.lastname)} required className={classes["input"]} />
						</div>
						<Input name="subject" label={I18n.trslt(I18n.asset.pages.contact.labels.subject)} required className={classes["input"]} />
						<Input placeholder="johndoe@gmail.com" name="email" label={I18n.trslt(I18n.asset.pages.contact.labels.email)} required className={classes["input"]} />
						<TextArea
							placeholder="Type your message..."
							name="message"
							label={I18n.trslt(I18n.asset.pages.contact.labels.message)}
							required
							className={classes["textarea-container"]}
						/>
					</div>

					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} type="submit" fullwidth>
						{I18n.trslt(I18n.asset.pages.contact.button)}
					</Button>
				</Form>
			</div>
			<div className={classes["design"]}>
				<img src={EllipsisImage} alt="Ellipsis design" className={classes["image"]} />
			</div>
		</PageTemplate>
	);
}
