import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import { memo } from "react";
import classes from "./classes.module.scss";
import Banner from "./Banner";
import Storytelling from "./Storytelling";
import FAQ from "./FAQ";
import FeaturedProjects from "./FeaturedProjects";
import WhyJoin from "./WhyJoin";
import Marketplace from "./Marketplace";

function Home() {
	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.home.page_title)} mainSectionClassName={classes["root"]}>
			<Banner />
			<WhyJoin />
			<FeaturedProjects />
			<Storytelling />
			<Marketplace />
			<FAQ />
		</PageTemplate>
	);
}

export default memo(Home);
