import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import Dropdown from "./Dropdown";
import EllipsisDesign from "assets/images/ellipsis-design.png";
import EllipsisDesignMini from "assets/images/ellipsis-design-mini.png";

export default function FAQ() {
	return (
		<section className={classes["root"]}>
			<Typography type="h2" weight="bold" className={classes["title"]}>
				{I18n.trslt(I18n.asset.pages.home.FAQ.title)}
			</Typography>

			<div className={classes["faq-container"]}>
				{Object.values(I18n.asset.pages.home.FAQ.questions).map((question, index) => (
					<Dropdown key={index} title={question.title} content={question.content} className={classes["dropdown"]} />
				))}
			</div>

			<img src={EllipsisDesign} alt="Ellipsis Design" className={classes["ellipsis-design"]} />
			<img src={EllipsisDesignMini} alt="Ellipsis Design Mini" className={classes["ellipsis-design-mini"]} />
		</section>
	);
}
