import { UserProvider } from "contexts/User";
import "./di/register";
import Routing from "./Routing";
import { AuthProvider } from "contexts/Auth";
import I18n from "components/materials/I18n";

function App() {
	return (
		<AuthProvider>
			<UserProvider>
				<I18n>
					<Routing />
				</I18n>
			</UserProvider>
		</AuthProvider>
	);
}

export default App;
