import { ValidationError } from "common/resources/Resource";
import UserResetPwdRequestResource from "common/resources/User/UserResetPwdRequestResource";
import AuthPageTemplate from "components/PageTemplate/AuthPageTemplate";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";
import Form from "components/materials/Form";
import Input from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "services/AuthService";
import { container } from "tsyringe";
import classes from "./classes.module.scss";

const modules = container.resolve(ModuleConfig).get().modules;
const authService = container.resolve(AuthService);

export default function ResetPassword() {
	const navigate = useNavigate();
	const location = useLocation();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);

			const { pinCode, email } = Object.fromEntries(new URLSearchParams(location.search));

			const userRequest = UserResetPwdRequestResource.hydrate<UserResetPwdRequestResource>({
				confirmPassword: formData["confirmPassword"] as string,
				password: formData["password"] as string,
				pinCode: pinCode as string, // TODO: Remove ?
				email: email as string, // TODO: Remove ?
			});

			userRequest
				.validateOrReject()
				.then(() => {
					authService
						.resetPassword(userRequest)
						.then(() => {
							navigate(modules.pages.Login.props.path);
						})
						.catch((error) => {
							console.error({ error });
							if (error instanceof Array) {
								setErrors(error);
							}
						});
				})
				.catch((error) => {
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[navigate, location],
	);

	return (
		<AuthPageTemplate tabTitle={I18n.trslt(I18n.asset.pages.reset_password.page_title)}>
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<Typography type="h1" weight="bold">
						{I18n.trslt(I18n.asset.pages.reset_password.title.first)}
						<Typography type="span" weight="bold" className={classes["secondary-title"]}>
							&nbsp;{I18n.trslt(I18n.asset.pages.reset_password.title.second)}
						</Typography>
					</Typography>
					<Typography type="p" weight="regular" size="large">
						{I18n.trslt(I18n.asset.pages.reset_password.subtitle)}
					</Typography>
				</div>

				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<Input name="password" label={I18n.trslt(I18n.asset.pages.reset_password.labels.password)} type="password" className={classes["input"]} />

					<Input
						name="confirmPassword"
						label={I18n.trslt(I18n.asset.pages.reset_password.labels.password_confirm)}
						type="password"
						additionalInfo={I18n.trslt(I18n.asset.pages.reset_password.labels.password_additionnal)}
						className={classes["input"]}
					/>

					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} type="submit" fullwidth>
						{I18n.trslt(I18n.asset.pages.reset_password.reset_button)}
					</Button>
				</Form>
			</div>
		</AuthPageTemplate>
	);
}
