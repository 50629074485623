import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import En from "assets/images/en.png";
import Fr from "assets/images/fr.png";
import classNames from "classnames";
import I18n from "components/materials/I18n";
import I18nStore from "components/materials/I18n/I18nStore";
import { useCallback, useEffect, useRef, useState } from "react";
import { match } from "ts-pattern";

type IProps = {
	className?: string;
};

export default function LangSelector(props: IProps) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);

	const toggleMenu = useCallback(() => {
		setIsMenuOpen((prev) => !prev);
	}, []);

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (!isMenuOpen) return;
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setIsMenuOpen(false);
			}
		},
		[isMenuOpen],
	);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<div className={classNames(classes["root"], props.className)}>
			<div className={classes["menu-container"]} onClick={toggleMenu}>
				<div className={classes["lang-container"]}>
					{match(I18n.getLang())
						.with("fr", () => <img src={Fr} alt="Fr" className={classes["lang"]} />)
						.otherwise(() => (
							<img src={En} alt="En" className={classes["lang"]} />
						))}
				</div>

				<ChevronDownIcon className={classes["icon"]} />

				{isMenuOpen && (
					<div className={classes["menu"]} ref={menuRef}>
						<div className={classes["row"]} onClick={() => I18nStore.getInstance().toggleTo("en")}>
							<div className={classes["lang-container"]}>
								<img src={En} alt="En" className={classes["lang"]} />
							</div>
							<Typography type="span" size="medium" weight="medium">
								{I18n.trslt(I18n.asset.component.lang_selector.en)}
							</Typography>
						</div>

						<div className={classes["row"]} onClick={() => I18nStore.getInstance().toggleTo("fr")}>
							<div className={classes["lang-container"]}>
								<img src={Fr} alt="Fr" className={classes["lang"]} />
							</div>
							<Typography type="span" size="medium" weight="medium">
								{I18n.trslt(I18n.asset.component.lang_selector.fr)}
							</Typography>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
