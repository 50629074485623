"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Di {
    static getAnchorService() {
        if (!this.anchorService)
            throw new Error("AnchorService not set");
        return this.anchorService;
    }
    static setAnchorService(anchorService) {
        this.anchorService = anchorService;
    }
    static getFileService() {
        if (!this.fileService)
            throw new Error("FileService not set");
        return this.fileService;
    }
    static setFileService(fileService) {
        this.fileService = fileService;
    }
    static getProjectService() {
        if (!this.projectService)
            throw new Error("ProjectService not set");
        return this.projectService;
    }
    static setProjectService(projectService) {
        this.projectService = projectService;
    }
    static getPosterService() {
        if (!this.posterService)
            throw new Error("PosterService not set");
        return this.posterService;
    }
    static setPosterService(posterService) {
        this.posterService = posterService;
    }
    static getRoleService() {
        if (!this.roleService)
            throw new Error("RoleService not set");
        return this.roleService;
    }
    static setRoleService(roleService) {
        this.roleService = roleService;
    }
    static getRuleService() {
        if (!this.ruleService)
            throw new Error("RuleService not set");
        return this.ruleService;
    }
    static setRuleService(ruleService) {
        this.ruleService = ruleService;
    }
    static getScenarioService() {
        if (!this.scenarioService)
            throw new Error("ScenarioService not set");
        return this.scenarioService;
    }
    static setScenarioService(scenarioService) {
        this.scenarioService = scenarioService;
    }
    static getUserService() {
        if (!this.userService)
            throw new Error("UserService not set");
        return this.userService;
    }
    static setUserService(userService) {
        this.userService = userService;
    }
    static getFormService() {
        if (!this.formService)
            throw new Error("formService not set");
        return this.formService;
    }
    static setFormService(formService) {
        this.formService = formService;
    }
    static getChoiceService() {
        if (!this.choiceService)
            throw new Error("choiceService not set");
        return this.choiceService;
    }
    static setChoiceService(choiceService) {
        this.choiceService = choiceService;
    }
}
exports.default = Di;
