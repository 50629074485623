import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import FrameDesign from "assets/images/frame-design.png";
import FeaturedProject from "./FeaturedProject";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import { Link } from "react-router-dom";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { Fragment, useCallback, useEffect, useState } from "react";
import ProjectService from "services/ProjectService";

const modules = container.resolve(ModuleConfig).get().modules;
const projectService = container.resolve(ProjectService);

export default function FeaturedProjects() {
	const [featuredProjects, setFeaturedProjects] = useState<ProjectResponseResource[]>([]);

	const fetchProjects = useCallback(async () => {
		const projects = await projectService.getProjects();
		const featuredProjects = projects.filter((project) => project.featured);
		setFeaturedProjects(featuredProjects);
	}, []);

	useEffect(() => {
		fetchProjects();
	}, [fetchProjects]);

	return (
		<section className={classes["root"]}>
			<div className={classes["title-container"]}>
				<Typography type="h2" weight="medium">
					{I18n.trslt(I18n.asset.pages.home.featured_projects.title)}
					<Typography type="span" weight="medium" color="primary" className={classes["inside-title"]}>
						&nbsp;{I18n.trslt(I18n.asset.pages.home.featured_projects.inside_title)}
					</Typography>
				</Typography>

				<Typography type="p" size="large" weight="regular" fontFamily="secondary">
					{I18n.trslt(I18n.asset.pages.home.featured_projects.subtitle)}
				</Typography>
			</div>

			<div className={classes["projects-container"]}>
				<img src={FrameDesign} alt="Frame design" className={classes["frame-image"]} />

				<div className={classes["projects"]}>
					{featuredProjects.map((project) => (
						<Fragment key={project.id}>
							<FeaturedProject project={project} />
							<div className={classes["separator"]} />
						</Fragment>
					))}
				</div>
			</div>

			<div className={classes["button-container"]}>
				<Link to={modules.pages.Projects.props.path}>
					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE}>
						{I18n.trslt(I18n.asset.pages.home.featured_projects.discover_project)}
					</Button>
				</Link>
			</div>
		</section>
	);
}
