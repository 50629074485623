import UserAccountInfoResponseResource from "common/resources/User/UserAccountInfoResponseResource";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./Auth";
import { container } from "tsyringe";
import UserService from "services/UserService";

export type IUserContext = {
	isLoading: boolean;
	user: UserAccountInfoResponseResource | null;
	updateUser: (user: UserAccountInfoResponseResource | null) => void;
};

type IProps = {
	children: ReactNode;
};

export const UserContext = createContext<IUserContext>(undefined!);

const userService = container.resolve(UserService);

export function UserProvider(props: IProps) {
	const { jwtContent, isLoading: isAuthLoading } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [user, setUser] = useState<UserAccountInfoResponseResource | null>(null);

	// lors du changement jwt dans le authContext alors je recupere le user et le set dans mon userContext
	//todo : Si l'email du jwt dans le cookie n'est pas présente en bdd => Error
	useEffect(() => {
		setIsLoading(true);
		if (!jwtContent) {
			setUser(null);
			setIsLoading(false);
			return;
		}

		userService
			.getAccount()
			.then((user) => setUser(jwtContent ? user : null))
			.finally(() => setIsLoading(false));
	}, [jwtContent]);

	return (
		<UserContext.Provider
			value={{
				isLoading: isAuthLoading || isLoading,
				user,
				updateUser: setUser,
			}}>
			{props.children}
		</UserContext.Provider>
	);
}
