import ContactUsRequestResource from "common/resources/Form/ContactUsRequestResource";
import ContactUsResponseResource from "common/resources/Form/ContactUsResponseResource";
import SubscribeRequestResource from "common/resources/Form/SubscribeRequestResource";
import SubscribeResponseResource from "common/resources/Form/SubscribeResponseResource";
import { singleton } from "tsyringe";
import BaseApi from "../BaseApi";

@singleton()
export default class FormApi extends BaseApi {
	private baseurl = `${this.apiUrl}/form`;

	public async contactUs(form: ContactUsRequestResource) {
		const url = `${this.baseurl}/contact-us`;
		return this.postRequest<ContactUsResponseResource>(url, { ...form });
	}

	public async subscribe(form: SubscribeRequestResource) {
		const url = `${this.baseurl}/subscribe`;
		return this.postRequest<SubscribeResponseResource>(url, { ...form });
	}
}
