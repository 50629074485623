import classes from "./classes.module.scss";
import ExploreDesign from "assets/images/explore-design.svg";
import MarketplaceDesign from "assets/images/marketplace-design.svg";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";

export default function Marketplace() {
	return (
		<section className={classes["root"]} id="marketplace">
			<div className={classes["content-container"]}>
				<div className={classes["header"]}>
					<div className={classes["title-container"]}>
						<Typography type="h2" weight="medium" className={classes["title"]}>
							{I18n.trslt(I18n.asset.pages.home.marketplace.title)}
						</Typography>

						<Typography type="h2" color="primary" weight="medium" className={classes["inside-title"]}>
							{I18n.trslt(I18n.asset.pages.home.marketplace.inside_title)}
						</Typography>
					</div>

					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.home.marketplace.subtitle)}
					</Typography>
				</div>

				<div className={classes["body"]}>
					<div className={classes["block"]}>
						<img src={ExploreDesign} alt="" className={classes["image"]} />

						<div className={classes["content"]}>
							<Typography type="h3" weight="medium">
								{I18n.trslt(I18n.asset.pages.home.marketplace.explore_title)}
							</Typography>

							<Typography type="p" weight="regular" size="medium">
								{I18n.trslt(I18n.asset.pages.home.marketplace.explore_content)}
							</Typography>
						</div>
					</div>

					<div className={classes["block"]}>
						<img src={MarketplaceDesign} alt="" className={classes["image"]} />

						<div className={classes["content"]}>
							<Typography type="h3" weight="medium">
								{I18n.trslt(I18n.asset.pages.home.marketplace.certified_title)}
							</Typography>

							<Typography type="p" weight="regular" size="medium">
								{I18n.trslt(I18n.asset.pages.home.marketplace.certified_content)}
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
