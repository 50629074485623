import PageTemplate from "components/PageTemplate";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Typography from "components/elements/Typography";

export default function PrivacyPolicy() {
	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.privacy_policy.page_title)} mainSectionClassName={classes["root"]}>
			<div className={classes["header"]}>
				<Typography type="h1" weight="bold">
					{I18n.trslt(I18n.asset.pages.privacy_policy.title)}
				</Typography>

				<Typography type="p" weight="medium" size="large">
					{I18n.trslt(I18n.asset.pages.privacy_policy.subtitle)}
				</Typography>
			</div>

			<div className={classes["body"]}>
				<div className={classes["block"]}>
					<Typography type="h2" weight="bold">
						{I18n.trslt(I18n.asset.pages.privacy_policy.foreword.title)}
					</Typography>
					<Typography type="p" weight="bold" size="xlarge">
						{I18n.trslt(I18n.asset.pages.privacy_policy.foreword.content)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.privacy_policy.data_protection.title)}
					</Typography>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.privacy_policy.data_protection.content)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.privacy_policy.collect.title)}
					</Typography>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.privacy_policy.collect.content)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.privacy_policy.data_security.title)}
					</Typography>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.privacy_policy.data_security.content)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.privacy_policy.personal_data.title)}
					</Typography>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.privacy_policy.personal_data.content)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.privacy_policy.your_rights.title)}
					</Typography>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.privacy_policy.your_rights.content)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.privacy_policy.chart.title)}
					</Typography>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.privacy_policy.chart.content)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.privacy_policy.contact.title)}
					</Typography>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.privacy_policy.contact.content)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.privacy_policy.appeal.title)}
					</Typography>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.privacy_policy.appeal.content)}
					</Typography>
				</div>
			</div>
		</PageTemplate>
	);
}
