import PageTemplate from "components/PageTemplate";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import Typography from "components/elements/Typography";
import Table from "./Table";
import { container } from "tsyringe";
import ProjectService from "services/ProjectService";
import { useCallback, useEffect, useState } from "react";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import PosterModal from "./PosterModal/PosterModal";
import Pagination from "components/elements/Pagination";

const projectService = container.resolve(ProjectService);
const projectsPerPage = 20;


function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}

function clampPage(page: number, totalPages: number): number {
	return clamp(page, 1, Math.max(1, totalPages));
}

export default function MyProjects() {
	const [projectList, setProjectList] = useState<ProjectResponseResource[]>([]);
	const [isOpen, setIsOpen] = useState(false);
	const [posterUrl, setPosterUrl] = useState("");
	const [projectCount, setProjectCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	const getProjectList = useCallback(async (currentPage: number) => {
		const pagination = { skip: (currentPage - 1) * projectsPerPage, take: projectsPerPage };
		setProjectList(await projectService.getMyProjects(pagination));
	}, []);

	const open = useCallback(() => {
		setIsOpen(true);
	}, []);

	const close = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onViewImageClick = useCallback(
		(url: string) => {
			open();
			setPosterUrl(url);
		},
		[open],
	);

	const countProjects = useCallback(async () => {
		const total = await projectService.countMyProjects();

		if (total === null) {
			console.error("Total project count is not found");
			return;
		}

		setProjectCount(total);
	}, []);

	const getTotalPages = useCallback(() => Math.max(1, Math.ceil(projectCount / projectsPerPage)), [projectCount]);

	const onChangePage = useCallback(
		async (currentPage: number) => {
			setCurrentPage(clampPage(currentPage, getTotalPages()));
			await getProjectList(currentPage);
		},
		[getProjectList, getTotalPages],
	);

	useEffect(() => {
		const initialPage = 1;

		countProjects();
		getProjectList(initialPage);
	}, [countProjects, getProjectList]);

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.my_projects.page_title)} mainSectionClassName={classes["root"]}>
			<Typography type="h1" weight="bold" className={classes["title"]}>
				{I18n.trslt(I18n.asset.pages.my_projects.title)}
			</Typography>
			<Table projectList={projectList} onViewImageClick={onViewImageClick} />
			<PosterModal isOpen={isOpen} posterUrl={posterUrl} onClose={close} />
			<div className={classes["pagination"]}>
				<Pagination lastPage={getTotalPages()} currentPage={currentPage} onChangePage={onChangePage} />
			</div>
		</PageTemplate>
	);
}
