import { EProjectTheme } from "common/enums/ProjectTheme/ProjectTheme";
import I18n from "components/materials/I18n";

export const Themes = [
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.comedie), value: EProjectTheme.comedie },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.comedie_romantique), value: EProjectTheme.comedie_romantique },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.comedie_dramatique), value: EProjectTheme.comedie_dramatique },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.biopic), value: EProjectTheme.biopic },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.thriller), value: EProjectTheme.thriller },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.film_daction), value: EProjectTheme.film_daction },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.film_policier), value: EProjectTheme.film_policier },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.film_dhorreur), value: EProjectTheme.film_dhorreur },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.film_historique), value: EProjectTheme.film_historique },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.science_fiction), value: EProjectTheme.science_fiction },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.film_danimation), value: EProjectTheme.film_danimation },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.comedie_musicale), value: EProjectTheme.comedie_musicale },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.dramédie), value: EProjectTheme.dramedie },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.comedie_absurde), value: EProjectTheme.comedie_absurde },
	{ label: I18n.trslt(I18n.asset.component.theme_selector.themes.thriller_psychologique), value: EProjectTheme.thriller_psychologique }
  ];
