import PageTemplate from "components/PageTemplate";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Typography from "components/elements/Typography";

export default function TermsOfUse() {
	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.terms_of_use.page_title)} mainSectionClassName={classes["root"]}>
			<div className={classes["header"]}>
				<Typography type="h1" weight="bold">
					{I18n.trslt(I18n.asset.pages.terms_of_use.title)}
				</Typography>
			</div>

			<div className={classes["body"]}>
				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.subtitle)}
					</Typography>
					<Typography type="p" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.date)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.watch_us)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.collect)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.use_data)}</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.definitions.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.definitions.site)}</Typography>
					<ul>
						{I18n.asset.pages.terms_of_use.definitions.items.map((value, index) => (
							<li>
								<Typography key={index} type="p">
									{value}
								</Typography>
							</li>
						))}
					</ul>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.subtitle)}</Typography>
					<Typography type="p" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.types_of_data_collected.title)}
					</Typography>
					<Typography type="p" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.types_of_data_collected.personal_data.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.types_of_data_collected.personal_data.description)}</Typography>
					<ul>
						{I18n.asset.pages.terms_of_use.data_collection_and_use.types_of_data_collected.personal_data.items.map((value, index) => (
							<li>
								<Typography key={index} type="p">
									{value}
								</Typography>
							</li>
						))}
					</ul>
					<Typography type="p" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.types_of_data_collected.usage_data.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.types_of_data_collected.usage_data.description)}</Typography>

					<Typography type="p" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.types_of_data_collected.tracking_cookies_data.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.types_of_data_collected.tracking_cookies_data.description)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.types_of_data_collected.tracking_cookies_data.definition)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.types_of_data_collected.tracking_cookies_data.consent)}</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.use_of_data.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_collection_and_use.use_of_data.description)}</Typography>
					<ul>
						{I18n.asset.pages.terms_of_use.data_collection_and_use.use_of_data.items.map((value, index) => (
							<li>
								<Typography key={index} type="p">
									{value}
								</Typography>
							</li>
						))}
					</ul>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.legal_basis.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.legal_basis.description)}</Typography>
					<ul>
						{I18n.asset.pages.terms_of_use.legal_basis.legal_basis.map((value, index) => (
							<li>
								<Typography key={index} type="p">
									{value}
								</Typography>
							</li>
						))}
					</ul>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_retention.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_retention.description)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_retention.usage_data_retention)}</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_transfer.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_transfer.description)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_transfer.international_transfer)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_transfer.security_measures)}</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_disclosure.title)}
					</Typography>
					<Typography type="p" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_disclosure.law_enforcement.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_disclosure.law_enforcement.description)}</Typography>

					<Typography type="p" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_disclosure.legal_requirements.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_disclosure.legal_requirements.description)}</Typography>
					<ul>
						{I18n.asset.pages.terms_of_use.data_disclosure.legal_requirements.items.map((value, index) => (
							<li>
								<Typography key={index} type="p">
									{value}
								</Typography>
							</li>
						))}
					</ul>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.data_security.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.data_security.description)}</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.user_rights.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.user_rights.description)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.user_rights.contact_info)}</Typography>
					<ul>
						{I18n.asset.pages.terms_of_use.user_rights.rights_list.map((value, index) => (
							<li>
								<Typography key={index} type="p">
									{value}
								</Typography>
							</li>
						))}
					</ul>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.user_rights.proof_of_identity)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.user_rights.complaint)}</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.service_providers.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.service_providers.description)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.service_providers.access_restriction)}</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.external_links.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.external_links.description)}</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.policy_updates.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.policy_updates.description)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.policy_updates.notification)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.policy_updates.advice)}</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.contact.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.contact.description)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.contact.contact_info.contact_person)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.contact.contact_info.email)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.contact.contact_info.address)}</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.terms_of_use.legal_notice.title)}
					</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.legal_notice.description)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.legal_notice.publication_director)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.legal_notice.hosting_info.company)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.legal_notice.hosting_info.details)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.legal_notice.hosting_info.subsidiary)}</Typography>
					<Typography type="p">{I18n.trslt(I18n.asset.pages.terms_of_use.legal_notice.hosting_info.publication_director)}</Typography>
				</div>
			</div>
		</PageTemplate>
	);
}
