import PageTemplate from "components/PageTemplate";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import FrameDesign from "assets/images/frame-design.png";
import Typography from "components/elements/Typography";
import FeaturedProject from "../Home/FeaturedProjects/FeaturedProject";
import ProjectCard from "components/materials/ProjectCard";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { useState, useCallback, useEffect, Fragment } from "react";
import { container } from "tsyringe";
import ProjectService from "services/ProjectService";

const projectService = container.resolve(ProjectService);

export default function Projects() {
	const [projects, setProjects] = useState<ProjectResponseResource[]>([]);

	const fetchProjects = useCallback(async () => {
		const projects = await projectService.getProjects();
		setProjects(projects);
	}, []);

	useEffect(() => {
		fetchProjects();
	}, [fetchProjects]);

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.projects.page_title)} mainSectionClassName={classes["root"]}>
			<Typography type="h1" weight="bold" className={classes["title"]}>
				{I18n.trslt(I18n.asset.pages.projects.title)}
				<Typography type="span" weight="bold" color="secondary" className={classes["inside-title"]}>
					&nbsp;{I18n.trslt(I18n.asset.pages.projects.inside_title)}
				</Typography>
			</Typography>

			<div className={classes["body-container"]}>
				<img src={FrameDesign} alt="Frame design" className={classes["frame-image"]} />

				<div className={classes["projects"]}>
					{projects.map((project) => {
						if (!project.featured) return null;
						return (
							<Fragment key={project.id}>
								<FeaturedProject project={project} />
								<div className={classes["separator"]} />
							</Fragment>
						);
					})}
					{projects.map((project) => {
						if (project.featured) return null;
						return (
							<Fragment key={project.id}>
								<ProjectCard project={project} />
								<div className={classes["separator"]} />
							</Fragment>
						);
					})}
				</div>
			</div>
		</PageTemplate>
	);
}
