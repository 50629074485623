import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { format } from "date-fns";
import { match } from "ts-pattern";
import { container } from "tsyringe";
import StatusIcon from "components/materials/StatusIcon";
import DropDown from "components/elements/DropDown";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import ProjectService from "services/admin/ProjectService";
import ProjectUpdateStatusRequestResource from "common/resources/Project/ProjectUpdateStatusRequestResource";
import Options from "./Options";
import Chip from "components/elements/Chip";
import I18n from "components/materials/I18n";

const projectService = container.resolve(ProjectService);

type IProps = {
	project: ProjectResponseResource;
	onProjectStatusChange: () => void;
	onProjectArchiveChange: () => void;
	onRowClick: (project: ProjectResponseResource) => void;
};

function formatDate(date: Date): string {
	return format(date, "yyyy-MM-dd");
}

export default function Row(props: IProps) {
	const getProjectDate = () => {
		if (!props.project.createdAt) return "";
		return new Date(formatDate(props.project.createdAt)).toLocaleDateString("fr-FR", { day: "numeric", month: "long", year: "numeric" });
	};

	const getProjectName = () => {
		return match(props.project.title)
			.with("fr", () => props.project.title)
			.otherwise(() => props.project.title_en!);
	};

	const handleStatusChange = async (index: number) => {
		const status = () => {
			switch (index) {
				case 0:
					return EProjectStatus.STUDYING;
				case 1:
					return EProjectStatus.ACCEPTED;
				case 2:
					return EProjectStatus.REFUSED;
				case 3:
					return EProjectStatus.UNSTUDIED;
				default:
					return EProjectStatus.STUDYING;
			}
		};

		const request = ProjectUpdateStatusRequestResource.hydrate<ProjectUpdateStatusRequestResource>({
			status: status(),
		});

		await projectService.updateStatus(props.project.id, request);
		props.onProjectStatusChange();
	};

	const onClick = () => {
		props.onRowClick(props.project);
	};

	const format = I18n.trslt(I18n.asset.enums.EProjectFormats[props.project.format]);
	const theme = I18n.trslt(I18n.asset.enums.EProjectThemes[props.project.theme]);

	return (
		<tr className={classes["root"]}>
			<td className={classes["td-name"]} onClick={onClick}>
				<Typography type="span" size="medium" weight="bold">
					{getProjectName()}
				</Typography>
				<div className={classes["responsive"]}>
					<StatusIcon status={props.project.status} />
					<Chip>{getProjectDate()}</Chip>
					<Chip>
						{props.project.author.firstName} {props.project.author.lastName}
					</Chip>
					<Chip>{theme}</Chip>
					<Chip>{format}</Chip>
				</div>
			</td>

			<td className={classes["td"]} onClick={onClick}>
				<Typography type="span" size="medium" weight="regular">
					{`${props.project.author.firstName} ${props.project.author.lastName}`}
				</Typography>
			</td>

			<td className={classes["td"]}>
				<DropDown
					fixedMenuOnSmallScreen
					options={[
						<StatusIcon status={EProjectStatus.STUDYING} withDescription />,
						<StatusIcon status={EProjectStatus.ACCEPTED} withDescription />,
						<StatusIcon status={EProjectStatus.REFUSED} withDescription />,
						<StatusIcon status={EProjectStatus.UNSTUDIED} withDescription />,
					]}
					onOptionClick={handleStatusChange}
					menuClassName={classes["menu"]}>
					<div className={classes["status-row"]}>
						<StatusIcon status={props.project.status} withDescription />
					</div>
				</DropDown>
			</td>

			<td className={classes["td"]} onClick={onClick}>
				<Typography type="span" size="medium" weight="regular">
					{getProjectDate()}
				</Typography>
			</td>

			<td className={classes["options"]}>
				<Options
					project={props.project}
					onRowClick={props.onRowClick}
					onProjectStatusChange={props.onProjectStatusChange}
					onProjectArchiveChange={props.onProjectArchiveChange}
				/>
			</td>
		</tr>
	);
}
