import classes from "./classes.module.scss";
import useHead from "hooks/useHead";
import { PropsWithChildren } from "react";
import Header from "components/materials/Header";
import Footer from "components/materials/Footer";
import classNames from "classnames";

type IPageTemplateProps = {
	tabTitle: string;
	mainSectionClassName?: string;
};

type IProps = PropsWithChildren<IPageTemplateProps>;
export default function PageTemplate(props: IProps) {
	useHead({ title: props.tabTitle });
	return (
		<div className={classes["root"]}>
			<Header />
			<main className={classNames(classes["main"], props.mainSectionClassName)}>{props.children}</main>
			<Footer />
		</div>
	);
}
