import PageTemplate from "components/PageTemplate";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Typography from "components/elements/Typography";

export default function LegalNotice() {
	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.legal_notice.page_title)} mainSectionClassName={classes["root"]}>
			<div className={classes["header"]}>
				<Typography type="h1" weight="bold">
					{I18n.trslt(I18n.asset.pages.legal_notice.title)}
				</Typography>
			</div>

			<div className={classes["body"]}>
				<div className={classes["block"]}>
					<Typography type="p" size="xlarge" weight="bold">
						{I18n.trslt(I18n.asset.pages.legal_notice.subtitle)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.legal_notice.edition.title)}
					</Typography>

					<div className={classes["content"]}>
						<Typography type="p" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.legal_notice.edition.url)}
						</Typography>
						<Typography type="p" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.legal_notice.edition.company)}
						</Typography>
						<Typography type="p" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.legal_notice.edition.tva)}
						</Typography>
					</div>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.legal_notice.hosting.title)}
					</Typography>
					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.legal_notice.hosting.hosted)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.legal_notice.publication.title)}
					</Typography>
					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.legal_notice.publication.content)}
					</Typography>
				</div>

				<div className={classes["block"]}>
					<Typography type="h3" weight="bold">
						{I18n.trslt(I18n.asset.pages.legal_notice.contact.title)}
					</Typography>

					<div className={classes["content"]}>
						<Typography type="p" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.legal_notice.contact.mail)}
						</Typography>
						<Typography type="p" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.legal_notice.contact.letter)}
						</Typography>
						<Typography type="p" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.legal_notice.contact.legal)}
						</Typography>
					</div>
				</div>
			</div>
		</PageTemplate>
	);
}
