import React, { useState, useEffect } from "react";

type IProps = {
	componentName: string;
	props?: Record<string, unknown>;
};

export default function DynamicProjectPageLoader({ componentName, props = {} }: IProps) {
	const [Component, setComponent] = useState<React.ComponentType<any> | null>(null);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		import("components/pages/FeaturedProjects/".concat(componentName)).then((module) => setComponent(() => module.default)).catch((err) => setError(err));
	}, [componentName]);

	if (error) {
		alert(`Error loading component: ${componentName}`);
		console.error(error);
		return null;
	}

	if (Component) {
		return <Component {...props} />;
	}

	return <div>Loading...</div>;
}
