import { PropsWithChildren } from "react";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import useHead from "hooks/useHead";
import ClapActionLogo from "assets/images/logo-clapaction.png";
import Typography from "components/elements/Typography";
import AuthPageTemplateAsideImage from "assets/images/authtpageemplate-aside.png";

type IAuthPageTemplateProps = {
	tabTitle: string;
};

type IProps = PropsWithChildren<IAuthPageTemplateProps>;

export default function AuthPageTemplate(props: IProps) {
	useHead({ title: props.tabTitle });

	return (
		<div className={classes["root"]}>
			<div className={classes["content"]}>
				<main className={classes["main"]}>{props.children}</main>
			</div>

			<aside className={classes["aside"]}>
				<img src={AuthPageTemplateAsideImage} alt="aside asset" className={classes["image"]} />

				<div className={classes["for-flex"]} />

				<img src={ClapActionLogo} alt="clapaction logo" className={classes["logo"]} />

				<Typography type="p" weight="regular" size="medium" className={classes["footer"]}>
					{I18n.trslt(I18n.asset.component.authpagetemplate.footer.copyright)}
				</Typography>
			</aside>
		</div>
	);
}
