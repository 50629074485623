"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EProjectTheme = void 0;
exports.EProjectTheme = {
    comedie: "comedie",
    comedie_romantique: "comedie_romantique",
    comedie_dramatique: "comedie_dramatique",
    biopic: "biopic",
    thriller: "thriller",
    film_daction: "film_daction",
    film_policier: "film_policier",
    film_dhorreur: "film_dhorreur",
    film_historique: "film_historique",
    science_fiction: "science_fiction",
    film_danimation: "film_danimation",
    comedie_musicale: "comedie_musicale",
    dramedie: "dramedie",
    comedie_absurde: "comedie_absurde",
    thriller_psychologique: "thriller_psychologique",
};
