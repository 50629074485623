import { useEffect } from "react";

export type IProps = {
	from: {
		enabled: boolean;
		props?: {
			[key: string]: unknown;
		};
	};
	onDisabled?: () => Promise<void> | void | undefined;
	onDisabledComponent?: React.ReactNode | undefined;
	children: React.ReactNode;
};

export default function ModulePage({ onDisabled, onDisabledComponent, children, from: { enabled } }: IProps) {
	useEffect(() => {
		!enabled && onDisabled?.();
	}, [enabled, onDisabled]);

	if (!enabled) return onDisabledComponent;
	return <>{children}</>;
}
