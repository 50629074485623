import classes from "./classes.module.scss";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";

type IProps = {
	options: React.ReactNode[];
	chevronDirection?: "down" | "right";
	onOptionClick: (index: number) => Promise<void> | void;
	menuClassName?: string;
	childrenClassName?: string;
	children?: React.ReactNode;
	onChange?: (value: string) => void;
	fixedMenuOnSmallScreen?: boolean;
};

export default function DropDown(props: IProps) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);

	const toggleMenu = useCallback(() => {
		setIsMenuOpen((prev) => !prev);
	}, []);

	const listOptions = props.options.map((option, index) => (
		<div key={index} className={classes["row"]} onClick={() => props.onOptionClick(index)}>
			{option}
		</div>
	));

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (!isMenuOpen) return;
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setIsMenuOpen(false);
			}
		},
		[isMenuOpen],
	);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<div className={classes["root"]}>
			<div className={classNames(classes["menu-container"], props.childrenClassName)} onClick={toggleMenu}>
				{props.children}

				{(props.chevronDirection === "down" || !props.chevronDirection) && <ChevronDownIcon className={classes["icon"]} />}
				{props.chevronDirection === "right" && <ChevronRightIcon className={classes["icon"]} />}

				{isMenuOpen && (
					<div
						className={classNames(classes["menu"], props.menuClassName, {
							[classes["fixed"]!]: props.fixedMenuOnSmallScreen,
						})}
						ref={menuRef}>
						{listOptions}
					</div>
				)}
				{props.fixedMenuOnSmallScreen && (
					<div
						className={classNames(classes["background"], {
							[classes["is-open"]!]: isMenuOpen,
						})}
					/>
				)}
			</div>
		</div>
	);
}
