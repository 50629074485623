import React, { useContext, useRef } from "react";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { FormContext } from "../FormContext";
import Typography from "components/elements/Typography";

type IProps = {
	name: string;
	required?: boolean;
	className?: string;
};

export default function ConfirmationCodeInput(props: IProps) {
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(props.name);
	const hasErrors = errors?.length > 0;

	const input1 = useRef<HTMLInputElement>(null);
	const input2 = useRef<HTMLInputElement>(null);
	const input3 = useRef<HTMLInputElement>(null);
	const input4 = useRef<HTMLInputElement>(null);
	const input5 = useRef<HTMLInputElement>(null);

	const inputs = [input1, input2, input3, input4, input5];

	const moveFocus = (index: number) => {
		if (index < inputs.length - 1) {
			const nextInput = inputs[index + 1];

			if (nextInput) {
				nextInput.current?.focus();
			}
		}
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		const paste = event.clipboardData.getData("text");

		// Split the pasted data and set values
		paste.split("").forEach((value, index) => {
			if (index < inputs.length) {
				const input = inputs[index];
				if (input?.current) {
					input.current.value = value;
					moveFocus(index);
				}
			}
		});

		event.preventDefault();
	};

	return (
		<div className={classNames(classes["root"], props.className)}>
			<div className={classes["code-container"]}>
				{inputs.map((inputRef, index) => (
					<input
						key={index}
						name={`${props.name}-${index}`}
						required={props.required}
						ref={inputRef}
						type="text"
						maxLength={1}
						onInput={() => moveFocus(index)}
						onPaste={handlePaste}
						className={classes["input"]}
					/>
				))}
			</div>

			{hasErrors && (
				<div className={classes["errors-container"]}>
					{errors.map((message, i) => (
						<Typography type="p" size="small" weight="medium" key={i} color="error">
							{message}
						</Typography>
					))}
				</div>
			)}
		</div>
	);
}
