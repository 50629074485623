import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import Typography from "components/elements/Typography";
import CreatorDesign from "assets/images/creator-design.svg";
import PublicDesign from "assets/images/public-design.png";
import { useContext } from "react";
import { UserContext } from "contexts/User";
import { Link } from "react-router-dom";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

const modules = container.resolve(ModuleConfig).get().modules;

export default function WhyJoin() {
	const { user } = useContext(UserContext);

	return (
		<section className={classes["root"]}>
			<div className={classes["content"]}>
				<div className={classes["body"]}>
					<div className={classes["title-container"]}>
						<Typography type="h2" weight="medium" className={classes["title"]}>
							{I18n.trslt(I18n.asset.pages.home.why_join.title)}
							<Typography type="span" weight="medium" color="primary" className={classes["inside-title"]}>
								{I18n.trslt(I18n.asset.pages.home.why_join.inside_title)}
							</Typography>
						</Typography>
					</div>

					<Typography type="p" size="large" weight="medium">
						{I18n.trslt(I18n.asset.pages.home.why_join.content)}
					</Typography>
				</div>

				{!user && (
					<Link to={modules.pages.Register.props.path} style={{ textDecoration: "none" }}>
						<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} endIcon={<ArrowRightIcon />}>
							{I18n.trslt(I18n.asset.pages.home.why_join.button)}
						</Button>
					</Link>
				)}
			</div>

			<div className={classes["design"]}>
				<div className={classes["block"]}>
					<img src={CreatorDesign} alt="" className={classes["image"]} />

					<div className={classes["block-content"]}>
						<Typography type="h3" weight="bold">
							{I18n.trslt(I18n.asset.pages.home.why_join.creator_title)}
						</Typography>
						<Typography type="p" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.home.why_join.creator_content)}
						</Typography>
					</div>
				</div>

				<div className={classes["block"]}>
					<img src={PublicDesign} alt="" className={classes["image"]} />

					<div className={classes["block-content"]}>
						<Typography type="h3" weight="bold">
							{I18n.trslt(I18n.asset.pages.home.why_join.public_title)}
						</Typography>
						<Typography type="p" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.home.why_join.public_content)}
						</Typography>
					</div>
				</div>
			</div>
		</section>
	);
}
