import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Row from "./Row";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { format } from "date-fns";
import { useCallback } from "react";
import { match } from "ts-pattern";


function formatDate(date: Date): string {
	return format(date, "yyyy-MM-dd");
}

export type IProps = {
	projectList: ProjectResponseResource[];
	onViewImageClick: (url: string) => void;
};

export default function Table(props: IProps) {
	const onViewImageClick = useCallback(
		(project: ProjectResponseResource) => {
			const posterUrl = project.poster?.file?.url;
			if (!posterUrl) return;
			props.onViewImageClick(posterUrl);
		},
		[props],
	);

	return (
		<div className={classes["root"]}>
			<table className={classes["table"]}>
				<thead className={classes["thead"]}>
					<tr>
						<th className={classes["th-name"]}>
							<Typography type="p" weight="bold" size="medium">
								{I18n.trslt(I18n.asset.pages.my_projects.table.headers.project_name)}
							</Typography>
						</th>
						<th className={classes["th-ai-image"]}>
							<Typography type="p" weight="bold" size="medium" className={classes["ellipsis"]}>
								{I18n.trslt(I18n.asset.pages.my_projects.table.headers.ai_image)}
							</Typography>
						</th>
						<th className={classes["th-proof"]}>
							<Typography type="p" weight="bold" size="medium" className={classes["ellipsis"]}>
								{I18n.trslt(I18n.asset.pages.my_projects.table.headers.anchor_proof)}
							</Typography>
						</th>
						<th className={classes["th-status"]}>
							<Typography type="p" weight="bold" size="medium" className={classes["ellipsis"]}>
								{I18n.trslt(I18n.asset.pages.my_projects.table.headers.status)}
							</Typography>
						</th>
						<th className={classes["th-date"]}>
							<Typography type="p" weight="bold" size="medium" className={classes["ellipsis"]}>
								{I18n.trslt(I18n.asset.pages.my_projects.table.headers.date)}
							</Typography>
						</th>
					</tr>
				</thead>

				<tbody className={classes["tbody"]}>
					{props.projectList.map((project) => (
						<Row
							key={project.id}
							projectId={project.id}
							projectName={match(project.title)
								.with("fr", () => project.title)
								.otherwise(() => project.title_en!)}
							hasPoster={Boolean(project.poster?.file?.url)}
							hasAnchorProof={Boolean(project.scenario)}
							anchorProofLink={project.scenario?.anchor?.txLink ?? undefined}
							date={project.createdAt ? formatDate(project.createdAt) : ""}
							onViewImageClick={() => onViewImageClick(project) }
							projectStatus={project.status}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
}
