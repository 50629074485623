import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import { singleton } from "tsyringe";
import BaseAdminApi from "./BaseAdminApi";

@singleton()
export default class UserApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/users`;


	public async getByIdAdmin(id: string) {
		const url = `${this.baseurl}/${id}`;
		return this.getRequest<UserPublicResponseResource>(url);
	}
}
