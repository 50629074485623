import FormApi from "api/clapaction/FormApi";
import ContactUsRequestResource from "common/resources/Form/ContactUsRequestResource";
import ContactUsResponseResource from "common/resources/Form/ContactUsResponseResource";
import SubscribeRequestResource from "common/resources/Form/SubscribeRequestResource";
import SubscribeResponseResource from "common/resources/Form/SubscribeResponseResource";
import FormServiceClassToken from "common/resources/injectables/FormServiceClassToken";
import { container, singleton } from "tsyringe";

@singleton()
export default class FormService implements FormServiceClassToken {
	private api: FormApi = container.resolve(FormApi);

	contactUs(form: ContactUsRequestResource): Promise<ContactUsResponseResource> {
		return this.api.contactUs(form);
	
	}

	subscribe(form: SubscribeRequestResource): Promise<SubscribeResponseResource> {
		return this.api.subscribe(form);
	}


}
