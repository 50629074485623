import PageTemplate from "components/PageTemplate";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import Typography from "components/elements/Typography";
import Form from "components/materials/Form";
import { ValidationError } from "common/resources/Resource";
import { useCallback, useState } from "react";
import Input from "components/materials/Form/InputElement";
import TextArea from "components/materials/Form/TextAreaElement";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import DragAndDropFile from "components/materials/Form/DragAndDropFileElement";
import EllipsisImage from "assets/images/ellipsis-design.png";
import ProjectCreateRequestResource from "common/resources/Project/ProjectCreateRequestResource";
import ProjectService from "services/ProjectService";
import { container } from "tsyringe";
import { useNavigate } from "react-router-dom";
import ModuleConfig from "configs/ModuleConfig";
import Select from "components/materials/Form/SelectElement";
import { EProjectFormat } from "common/enums/ProjectFormat/ProjectFormat";
import ThemeSelector from "components/materials/ThemeSelector";
import { EProjectTheme } from "common/enums/ProjectTheme/ProjectTheme";
import { EProjectReason } from "common/enums/ProjectReason";

const projectService = container.resolve(ProjectService);
const modules = container.resolve(ModuleConfig).get().modules;

export default function SubmitProject() {
	const navigate = useNavigate();
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedTheme, setSelectedTheme] = useState<EProjectTheme>(EProjectTheme.biopic);

	const onThemeSelect = useCallback((theme: EProjectTheme) => {
		setSelectedTheme(theme);
	}, []);

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);
			setIsLoading(true);

			const projectCreateRequest = ProjectCreateRequestResource.hydrate<ProjectCreateRequestResource>({
				title: formData["title"] as string,
				pitch: formData["pitch"] as string,
				format: formData["format"] as EProjectFormat,
				reason: formData["reason"] as EProjectReason,
				discord: formData["discord"] ? formData["discord"] as string : undefined,
				instagram: formData["instagram"] ? formData["instagram"] as string : undefined,
				x: formData["x"] ? formData["x"]as string : undefined,
				linkedin: formData["linkedin"] ? formData["linkedin"] as string : undefined,
				theme: selectedTheme,
				lang: I18n.getLang(),
			});

			const scenario = (formData["scenario"] as FileList)?.[0];
			const cv = (formData["cv"] as FileList)?.[0];

			projectCreateRequest
				.validateOrReject()
				.then(() => {
					projectService
						.createProject(projectCreateRequest, { scenario, cv })
						.then(() => {
							navigate(modules.pages.MyProjects.props.path);
						})
						.catch((error) => {
							setIsLoading(false);
							console.error({ error });
							if (error instanceof Array) {
								setErrors(error);
							}
						});
				})
				.catch((error) => {
					setIsLoading(false);
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[selectedTheme, navigate, setErrors],
	);

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.submit_project.page_title)} mainSectionClassName={classes["root"]}>
			<div className={classes["content"]}>
				<div className={classes["title-container"]}>
					<Typography type="h1" weight="bold">
						{I18n.trslt(I18n.asset.pages.submit_project.title)}
					</Typography>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.submit_project.subtitle)}
					</Typography>
				</div>

				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<div className={classes["inputs-container"]}>
						<Input
							name="title"
							required
							className={classes["input"]}
							label={I18n.trslt(I18n.asset.pages.submit_project.labels.project_title)}
							placeholder={I18n.trslt(I18n.asset.pages.submit_project.labels.project_title_placeholder)}
						/>
						<ThemeSelector onSelect={onThemeSelect} selectedTheme={selectedTheme} />

						<Select
							className={classes["select"]}
							name="format"
							label={I18n.trslt(I18n.asset.pages.submit_project.labels.project_format)}
							options={[
								{ label: I18n.trslt(I18n.asset.pages.submit_project.selections.format.long_metrage), value: EProjectFormat.long_metrage },
								{ label: I18n.trslt(I18n.asset.pages.submit_project.selections.format.court_metrage), value: EProjectFormat.court_metrage },
								{ label: I18n.trslt(I18n.asset.pages.submit_project.selections.format.serie), value: EProjectFormat.serie },
								{ label: I18n.trslt(I18n.asset.pages.submit_project.selections.format.documentaire), value: EProjectFormat.documentaire },
							]}
							defaultValue={EProjectFormat.long_metrage}
							required
						/>

						<Select
							className={classes["select"]}
							name="reason"
							label={I18n.trslt(I18n.asset.pages.submit_project.labels.project_reason)}
							options={[
								{ label: I18n.trslt(I18n.asset.pages.submit_project.selections.reason.protect), value: EProjectReason.PROTECT },
								{
									label: I18n.trslt(I18n.asset.pages.submit_project.selections.reason.protect_and_development_aid),
									value: EProjectReason.PROTECT_AND_DEVELOPMENT_AID,
								},
							]}
							defaultValue={EProjectReason.PROTECT}
							required
						/>

						<TextArea
							name="pitch"
							className={classes["textarea"]}
							label={I18n.trslt(I18n.asset.pages.submit_project.labels.project_pitch)}
							placeholder={I18n.trslt(I18n.asset.pages.submit_project.labels.project_pitch_placeholder)}
							additionalInfo={I18n.trslt(I18n.asset.pages.submit_project.labels.project_pitch_additionnal)}
						/>

						<Input
							name="discord"
							className={classes["input"]}
							label={"Discord"}
							placeholder={"https://discord.gg/"}
						/>

						<Input
							name="instagram"
							className={classes["input"]}
							label={"Instagram"}
							placeholder={"https://instagram.com/"}
						/>

						<Input
							name="x"
							className={classes["input"]}
							label={"X"}
							placeholder={"https://x.com/"}
						/>
						<Input
							name="linkedin"
							className={classes["input"]}
							label={"Linkedin"}
							placeholder={"https://www.linkedin.com/in/"}
						/>

						<DragAndDropFile
							name="cv"
							accept={[".pdf"]}
							description={I18n.trslt(I18n.asset.pages.submit_project.drag_and_drop.cv)}
							label={I18n.trslt(I18n.asset.pages.submit_project.labels.cv)}
						/>
						<DragAndDropFile
							name="scenario"
							accept={[".pdf"]}
							description={I18n.trslt(I18n.asset.pages.submit_project.drag_and_drop.scenario)}
							label={I18n.trslt(I18n.asset.pages.submit_project.labels.scenario)}
							keyErrorName={"sha256"}
						/>

						<Typography type="p" weight="regular" size="xsmall">
							{I18n.trslt(I18n.asset.pages.submit_project.confidentiality)}
						</Typography>
					</div>

					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} type="submit" isLoading={isLoading} fullwidth>
						<Typography type="span" size="medium" weight="medium">
							{I18n.trslt(I18n.asset.pages.submit_project.send_button)}
						</Typography>
					</Button>
				</Form>
			</div>
			<div className={classes["design"]}>
				<img src={EllipsisImage} alt="Ellipsis design" className={classes["image"]} />
			</div>
		</PageTemplate>
	);
}
