import { useCallback } from "react";
import classes from "./classes.module.scss";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

type IProps = {
	lastPage: number;
	currentPage: number;
	onChangePage: (page: number) => void;
	className?: string;
};

interface PageContainerProps {
    lastPage: number;
    currentPage: number;
    onChangePage: (page: number) => void;
}

interface PageButtonProps {
    pageNumber: number;
    currentPage: number;
    onChangePage: (page: number) => void;
}

export default function Pagination(props: IProps) {
	const onNextPage = useCallback(() => {
		if (props.currentPage === props.lastPage) return;
		props.onChangePage(props.currentPage + 1);
	}, [props]);

	const onPreviousPage = useCallback(() => {
		if (props.currentPage === 1) return;
		props.onChangePage(props.currentPage - 1);
	}, [props]);

	const PageButton = ({ pageNumber, currentPage, onChangePage }: PageButtonProps) => (
		<button
			key={pageNumber}
			className={classNames(classes["button"], {
				[classes["active"]!]: pageNumber === currentPage,
			})}
			onClick={() => onChangePage(pageNumber)}>
			{pageNumber}
		</button>
	);

	const Ellipsis = () => (
		<span key="ellipsis" className={classes["ellipsis"]}>
			...
		</span>
	);

	const renderPageContainer = ({ lastPage, currentPage, onChangePage }: PageContainerProps) => {
		const pages: (number | string)[] = [];

		if (lastPage <= 5) {
			for (let i = 1; i <= lastPage; i++) {
				pages.push(i);
			}
		} else {
			if (currentPage <= 3) {
				pages.push(1, 2, 3, 4, "...", lastPage);
			} else if (currentPage >= lastPage - 2) {
				pages.push(1, "...", lastPage - 3, lastPage - 2, lastPage - 1, lastPage);
			} else {
				pages.push(1, "...", currentPage - 1, currentPage, currentPage + 1, "...", lastPage);
			}
		}

		return pages.map((page, index) => {
			if (typeof page === "number") {
				return <PageButton key={index} pageNumber={page} currentPage={currentPage} onChangePage={onChangePage} />;
			} else {
				return <Ellipsis key={index} />;
			}
		});
	};

	return (
		<div className={classNames(classes["root"], props.className)}>
			<button className={classes["button"]} onClick={onPreviousPage} disabled={props.currentPage === 1}>
				<ChevronLeftIcon className={classes["icon"]} />
			</button>

			<div className={classes["page-container"]}>{renderPageContainer(props)}</div>

			<button className={classes["button"]} onClick={onNextPage} disabled={props.currentPage === props.lastPage}>
				<ChevronRightIcon className={classes["icon"]} />
			</button>
		</div>
	);
}
