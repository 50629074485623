import { EProjectFormat } from "common/enums/ProjectFormat/ProjectFormat";
import { EProjectReason } from "common/enums/ProjectReason";
import { EProjectTheme } from "common/enums/ProjectTheme/ProjectTheme";
import { EValidationError } from "common/enums/Validation/ValidationErrors";

const EProjectReasonImplementation: Record<EProjectReason, string> = {
	PROTECT: "Je dépose mon projet simplement pour le protéger",
	PROTECT_AND_DEVELOPMENT_AID: "Je dépose mon projet pour le protéger et le soumettre à l'aide au développement",
};

const EProjectFormatImplementation: Record<EProjectFormat, string> = {
	court_metrage: "Court-métrage",
	documentaire: "Documentaire",
	long_metrage: "Long-métrage",
	serie: "Série",
};

const EProjectThemeImplementation: Record<EProjectTheme, string> = {
	comedie: "Comédie",
	comedie_romantique: "Comédie romantique",
	comedie_dramatique: "Comédie dramatique",
	biopic: "Biopic",
	thriller: "Thriller",
	film_daction: "Film d'action",
	film_policier: "Film policier",
	film_dhorreur: "Film d'horreur",
	film_historique: "Film historique",
	science_fiction: "Science fiction",
	film_danimation: "Film d'animation",
	comedie_musicale: "Comédie musicale",
	dramedie: "Dramédie",
	comedie_absurde: "Comédie absurde",
	thriller_psychologique: "Thriller psychologique",
};

const EValidationErrorImplementation: Record<EValidationError, string> = {
	isArray: "Vous devez sélectionner au moins une valeur.",
	isBoolean: "Ce champ est obligatoire.",
	isEnum: "Ce champ est obligatoire.",
	isInt: "Assurez-vous d'entrer un nombre entier, sans décimales.",
	isNotEmpty: "Ce champ est obligatoire.",
	isNumber: "Ce champ est obligatoire. La valeur doit être un nombre.",
	isObject: "Ce champ est obligatoire.",
	isString: "Ce champ est obligatoire.",
	isUrl: "Veuillez entrer une URL valide.",
	max: "Le nombre doit être inférieur ou égal à {{var1}}.",
	min: "Le nombre doit être supérieur ou égal à {{var1}}.",
	badCredentials: "Identifiants incorrects. Veuillez réessayer.",
	emailNotExists: "L'email n'existe pas.",
	isEmail: "Veuillez entrer une adresse e-mail valide.",
	isNumberString: "Ce champ est obligatoire. La valeur doit être numérique.",
	isPositive: "Le nombre doit être positif.",
	maxLength: "La longueur du texte ne doit pas dépasser {{var1}} caractères.",
	passwordNotMatch: "Les mots de passe ne correspondent pas",
	isStrongPassword: "Le mot de passe doit être plus complexe pour des raisons de sécurité.",
	pinCodeNotValid: "Le code PIN n'est pas correct.",
	uniqueEmail: "Veuillez utiliser une adresse e-mail différente, celle-ci est déjà utilisée.",
	wrongPassword: "Le mot de passe que vous avez saisi est incorrect.",
	arrayNotEmpty: "Ce champ est obligatoire.",
	match: "Les valeurs ne correspondent pas.",
	minLength: "La longueur du texte doit être d'au moins {{var1}} caractères.",
	emailNotFound: "L'adresse e-mail que vous avez fournie n'est associée à aucun compte.",
	fileNotExists: "Le fichier n'existe pas.",
	emailCaseSensitive: "L'adresse e-mail doit être en minuscules.",
	anchorNotExists: "L'ancrage n'existe pas.",
	choiceNotExists: "Le choix n'existe pas.",
	mustBeInThePast: "La date doit être dans le passé.",
	uniqueSha256: "Il n'est pas possible de soumettre deux fois le même fichier.",
	invalidPhoneNumber: "Seul les formats français ou interlational sont acceptés.",
};

const trads = {
	common: {
		cancel: "Annuler",
		logout: "Déconnexion",
		search: "Rechercher",
		yes: "Oui",
		no: "Non",
		save: "Enregistrer",
		back: "Retour",
		delete: "Supprimer",
		add: "Ajouter",
		next: "Suivant",
	},
	pages: {
		admin_dashboard: {
			page_title: "Projets déposés",
			title: "Projets déposés",
			filters: {
				all_projects: "Tous les projets",
				accepted: "Validé",
				refused: "Refusé",
				studying: "En cours d'étude",
				unstudied: "Non étudiable",
				archived: "Archives",
			},
			table: {
				need_to_be_studied: {
					yes: "Oui",
					no: "Non",
				},
				headers: {
					project_name: "Nom du projet",
					author: "Auteur",
					status: "Statut",
					date: "Date",
				},
				buttons: {
					cv: "Télécharger le CV",
					scenario: "Télécharger le scénario",
					data: "Informations sur le projet",
					status: "Statut",
					archive: "Archiver",
					restore: "Restaurer",
				},
				no_cv: "Aucun CV",
				no_scenario: "Aucun scénario",
			},
			drawer: {
				author: "Auteur",
				infos: {
					title: "Informations",
					publication_date: "Publication :",
					genre: "Genre :",
					format: "Format :",
					reason: "Raison :",
				},
				buttons: {
					cv: "Télécharger le CV",
					scenario: "Télécharger le scénario",
					archive: "Archiver",
					restore: "Restaurer",
				},
			},
		},
		project: {
			page_title: "Page du projet",
			publication: "Publication: ",
			genre: "Genre: ",
			format: "Format: ",
			buttons: {
				readMore: "Lire plus",
				readLess: "Lire moins",
			},
			follow_us: "Suivez nous sur :",
		},
		featuredProjects: {
			grand_saut: {
				page_title: "Le grand saut",
				header: {
					original_idea: "Sur une idée originale de :",
					writer: "Écrit par :",
					publication: "Publication : le 10 mai 2024",
					genre: "Genre : Comédie romantique",
					format: "Format : Long-métrage",
					box: "Début de l'écriture de la première version",
					referent: "Agent référent: Isabelle Frère - société : If Only",
				},
				content: {
					title: "Le grand saut",
					subtitle: "Jusqu'à ce que la mort les sépare",
					description:
						"À la suite d'une rupture amoureuse et la perte de son travail, Antoine Lambert, trentenaire courtier en assurance-vie sombre dans la dépression. Symptôme d'une époque où la santé mentale est plus que jamais au cœur de nos vies. Lors d'une tentative de suicide ratée, il fait la connaissance de Lucie Saglio, trentenaire, dépressive depuis son enfance qui s'apprêtait elle aussi à en finir. De cette rencontre, une obsession va naitre : réaliser le suicide parfait ensemble. C'est avec ce projet, si funeste soit-il, qu'ils vont partir dans un ultime périple, succession d'événements à la fois sombres et comiques. Ils vont tout s'autoriser sans limites, retrouver un but à atteindre, du sens, un désir de vie. C'est peut-être ça, la meilleure façon de faire : le Grand Saut.",
				},
				vote: {
					title: "Vote en cours",
					description: "Avez-vous déjà expérimenté un désespoir si intense qu'il vous a conduit à envisager de mettre fin à votre existence?",
					options: ["Oui", "Non"],
				},
				action: {
					title: "Action à venir",
					description:
						"Bientôt, nous convierons trois membres de notre communauté à découvrir la première version du scénario. Les retours des lecteurs seront précieux pour les auteurs, réalisateurs et producteurs dans l'élaboration d'une deuxième version.",
				},
			},
			malgre_moi: {
				page_title: "Malgré moi",
				header: {
					author: "Laurent Bouhnik",
					publication: "Publication : le 10 mai 2024",
					genre: "Genre : Thriller psychologique",
					format: "Format : Long-métrage",
					referent: "Agent référent: Ghislaine Gracieux",
					box: "Version définitive en lecture - Début du casting",
					adaptation: "Adaptation du roman « Malgré Moi » de Claire Norton",
				},
				content: {
					title: "Malgré moi",
					subtitle: "Le pouvoir du deni",
					description:
						"Théo veut mourir. Sa sœur ne le souhaiterait pas et ses deux meilleurs amis non plus. Mais si Marine n'a pas pris l'avion, le Rio/Paris qui s'est abîmé en mer, elle aurait quitté Théo pour un autre homme. De quoi le rendre fou. C'est ce qu'il explique à son psychiatre. Mais comment savoir le vrai du faux quand Théo ne cesse d'arranger l'histoire à sa manière. Difficile de savoir la vérité si la réalité n'est jamais celle à laquelle on s'attend.",
				},
				vote: {
					title: "Vote en cours",
					description: "Est-ce que vous avez l'habitude de consulter une ou un psychologue / psychiatre de manière régulière ?",
					options: ["Oui", "Non"],
				},
				action: {
					title: "Action à venir",
					description:
						"Prochainement, deux d'entre vous auront le privilège de lire la version finale du scénario. Ensuite, la communauté exprimera ses préférences quant à l'acteur qu'elle souhaiterait voir incarner le rôle d'un psychiatre de plus de 60 ans.",
				},
			},
			kdanse: {
				page_title: "KDanse",
				header: {
					author: "Philippe Gray",
					publication: "Publication : le 10 mai 2024",
					genre: "Genre : Dramedie",
					format: "Format : Long-métrage",
					referent: "Agent référent: Fred Malek / Agence Caractère",
					box: "À la recherche d'un co-auteur ou d'une co-autrice. Idéalement, nous cherchons également un réalisateur ou une réalisatrice",
				},
				content: {
					title: "En K-Danse",
					subtitle: "Les champs de la résilience",
					description:
						"S'occupant de la ferme familiale et de sa mère atteinte d'un cancer, les jours deviennent de plus en plus difficiles pour David et son père, en proie à de gros soucis financiers. Le retour de son frère transgenre Émile, après 16 ans d'absence, chamboule leur quotidien.Alors que leur mère est placée en soins palliatifs, Émile a une idée pour gagner rapidement de l'argent : monter un groupe de K-Pop au sein du village et remporter le championnat de France qui a lieu dans un mois.",
				},
				vote: {
					title: "Vote en cours",
					description: "Êtes-vous familier avec l'univers de la K-POP ?",
					options: ["Oui", "Non"],
				},
				action: {
					title: "Action à venir",
					description: "Prochainement, vous pourrez nous assister en proposant des idées et en votant pour trouver le réalisateur idéal pour ce film.",
				},
			},
			friends: {
				page_title: "Celle qui n'avait pas vu Friends",
				header: {
					author: "Charlotte Gabris",
					publication: "Publication : le 10 mai 2024",
					genre: "Genre : Comédie absurde",
					format: "Format : Long-métrage",
					box: "Film en financement",
					referent: "Agent référent: Alexandra Schamis / AS. Talents",
				},
				content: {
					title: "Celle qui n'avait pas vu Friends",
					subtitle: "Quand tu te fais quitter le jour de ton mariage...",
					description:
						"Greg retrouve sa future femme Rachel quelques minutes avant la cérémonie. Elle est si belle… II a envie de dire « oh-mon-Dieu!!!! » en référence à la série Friends. Rachel ne comprends pas sa blague... Et pour cause, elle n'a jamais regardé cette série. Greg est sous le choc, il ne veut plus se marier.",
				},
				vote: {
					title: "Vote en cours",
					description: "Avez-vous déjà regardé la série Friends ?",
					options: ["Oui", "Non"],
				},
				action: {
					title: "Action à venir",
					description: "Bientôt, vous aurez l'opportunité de nous aider en participant au vote pour choisir l'interprète d'un des derniers rôles clés du film.",
				},
				casting: {
					title: "Casting confirmé",
					male: "Interprété par",
					female: "Interprétée par",
				},
			},
		},
		terms_of_use: {
			page_title: "Conditions d'utilisation",
			title: "Conditions d'utilisation",
			subtitle: "Politique de confidentialité",
			date: "Date de prise d'effet : 27/06/2024",
			watch_us: "WATCH'US (« nous », « notre », « nos ») exploite le site web https://www.techcannes.com/ et https://www.clapaction.com/",
			collect:
				"Cette page vous explique nos politiques en matière de collecte, d'utilisation et de communication des données à caractère personnel lorsque vous utilisez notre Site ainsi que les choix qui s'offrent à vous en ce qui concerne ces données.",
			use_data:
				"Nous utilisons vos données pour fournir et améliorer le Site. En utilisant le Site, vous consentez à la collecte et à l'utilisation d'informations conformément à la présente politique.",
			definitions: {
				title: "Définitions",
				site: "Site : Par Site on entend le site web https://www.techcannes.com/ et https://www.clapaction.com/ exploité par la société Watch'Us",
				items: [
					"Données à caractère personnel : Données à Caractère Personnel désigne des données concernant un individu vivant qui peut être identifié à partir de ces données (ou à partir de ces données et d'autres informations en notre possession ou susceptibles d'entrer en notre possession).",
					"Données d'Utilisation : Les Données d'Utilisation sont recueillies automatiquement et sont générées soit par l'utilisation du Site, soit par l'infrastructure du Site proprement dite (par exemple, durée de consultation d'une page).",
					"Cookies : Les cookies sont de petits fichiers enregistrés sur votre dispositif (ordinateur ou dispositif mobile).",
					"Responsable du Traitement : Responsable du Traitement désigne la personne physique ou morale (seule, ou conjointement ou en commun avec d'autres personnes) qui détermine les finalités et les modalités selon lesquelles toutes les données personnelles sont traitées ou doivent l'être. Aux fins de la présente Politique de Confidentialité, nous sommes un Responsable du Traitement de vos données à caractère personnel.",
					"Sous-Traitants (ou Prestataires de Services) : Le terme Sous-Traitant (ou Prestataire de Service) désigne toute personne physique ou morale qui traite les données au nom du Responsable du Traitement. Il se peut que nous fassions appel aux services de plusieurs Prestataires de Services afin de traiter vos données plus efficacement.",
					"Personne Concernée (ou Utilisateur) : Par Personne Concernée, on entend toute personne vivante qui utilise notre Site et est le sujet de Données à Caractère Personnel.",
				],
			},
			data_collection_and_use: {
				title: "Collecte et utilisation des données",
				subtitle: "Nous recueillons plusieurs types de données à différentes fins en vue de vous fournir notre Site et de l'améliorer.",
				types_of_data_collected: {
					title: "Types de données recueillies",
					personal_data: {
						title: "Données à Caractère Personnel",
						description:
							"Lorsque vous utilisez notre Site, il est possible que nous vous demandions de nous fournir certaines données personnelles nominatives qui peuvent servir à vous contacter ou à vous identifier (« Données à Caractère Personnel »). Les données personnelles nominatives peuvent comprendre, mais de manière non limitative :",
						items: ["Adresse e-mail", "Prénom et nom de famille", "Cookies et Données d'Utilisation"],
						use: "Nous utilisons vos Données à Caractère Personnel pour vous envoyer une réponse à votre demande effectuée via le formulaire de contact de notre site web. Nous nous engageons à ne pas vous envoyer de promotions et de démarchage commercial si vous ne nous donnez pas votre accord.",
					},
					usage_data: {
						title: "Données d'Utilisation",
						description:
							"Nous pouvons également recueillir des informations relatives au mode d'accès et d'utilisation du Site (« Données d'Utilisation »). Ces Données d'Utilisation peuvent comprendre des informations telles que l'adresse de protocole Internet (c.-à-d. l'adresse IP) de votre ordinateur, le type de navigateur, la version du navigateur, les pages de notre Site que vous consultez, la date et l'heure de votre visite, le temps passé sur ces pages, les identifiants uniques de dispositifs ainsi que d'autres données de diagnostic.",
					},
					tracking_cookies_data: {
						title: "Suivi et données de cookies",
						description:
							"Nous avons recours à des cookies et à d'autres technologies de suivi similaires pour effectuer un suivi des activités effectuées dans notre Site et nous conservons certaines informations.",
						definition:
							"Les cookies sont des fichiers à faible volume de données pouvant comporter un identifiant unique anonyme. Les cookies sont envoyés à votre navigateur depuis un site web et sont stockés sur votre dispositif.",
						consent: "Vous pouvez accepter ou refuser les Cookies directement via le panneau de gestion des Cookies en bas à droite du site web.",
					},
				},
				use_of_data: {
					title: "Utilisation des données",
					description: "La DCF utilise les données recueillies à des fins diverses :",
					items: [
						"Pour fournir et assurer notre Site",
						"Pour assurer assistance client",
						"Pour recueillir des données précieuses ou d'analyses qui nous permettront d'améliorer notre Site",
						"Pour contrôler l'utilisation de notre Site",
						"Pour détecter, prévenir et régler les problèmes techniques",
						"Pour répondre à toutes questions ou demandes de votre part via le formulaire de contact",
					],
				},
			},
			legal_basis: {
				title: "Fondement juridique du traitement des données à caractère personnel en vertu du Règlement Général relatif à la Protection des Données (RGPD)",
				description:
					"Si vous résidez dans l'Espace économique européen (EEE), le fondement juridique de La DCF en ce qui concerne la collecte et l'utilisation des données personnelles décrites dans la présente Politique de Confidentialité dépend des Données à Caractère Personnel que nous recueillons et du contexte précis dans lequel nous les recueillons.",
				legal_basis: [
					"Parce que vous nous avez autorisés à le faire",
					"Parce que nous avons un intérêt légitime à effectuer ce traitement et que vos droits ne priment pas sur cet intérêt légitime",
					"Pour respecter la loi",
				],
			},
			data_retention: {
				title: "Conservation des données",
				description:
					"Watch'Us ne conservera vos Données à Caractère Personnel que tant que cela sera nécessaire aux fins stipulées dans la présente Politique de Confidentialité. Nous conserverons et utiliserons vos Données à Caractère Personnel dans la mesure où cela sera nécessaire pour que nous puissions nous acquitter de nos obligations légales (par exemple pour respecter la législation en vigueur), résoudre des différends et appliquer nos accords et nos politiques. La durée légale de conservation de vos Données à Caractère Personnel est de 3 ans maximum.",
				usage_data_retention:
					"Watch'Us conservera également les Données d'Utilisation à des fins d'analyses internes. Les Données d'Utilisation sont généralement conservées pour une période plus courte sauf lorsque ces données sont utilisées pour renforcer la sécurité ou pour améliorer les fonctionnalités de notre Site ou si nous sommes légalement tenus de conserver ces données plus longtemps. La durée légale de conservation des Données d'Utilisation est de 13 mois maximum.",
			},
			data_transfer: {
				title: "Transfert des données",
				description:
					"Les informations vous concernant, notamment vos Données à Caractère Personnel, peuvent être transférées de votre région, province, pays ou autre division territoriale vers des ordinateurs – et stockées sur ces derniers – situés à un endroit où la législation relative à la protection des données diffère de celle du territoire où vous résidez.",
				international_transfer:
					"Si vous résidez hors de France et que vous choisissez de nous communiquer des informations, sachez que nous transférons les données, y compris les Données à Caractère Personnel, vers la France et que nous les y traitons. En acceptant la présente Politique de Confidentialité puis en soumettant ces informations, vous consentez à ce transfert.",
				security_measures:
					"Watch'Us prendra toutes les mesures raisonnablement nécessaires pour faire en sorte que vos données soient traitées de manière sécurisée et conformément à la présente Politique de Confidentialité, et vos Données à Caractère Personnel ne seront transférées vers aucune organisation ni aucun pays à moins que des contrôles adéquats ne soient en place, notamment en ce qui concerne la sécurité de vos données et d'autres données personnelles.",
			},
			data_disclosure: {
				title: "Communication de données",
				law_enforcement: {
					title: "Communication de données aux forces de l'ordre",
					description:
						"Dans certaines circonstances, Watch'Us peut être tenue de communiquer vos Données à Caractère Personnel si la loi l'exige ou en réponse à des demandes valides émanant de pouvoirs publics (par ex. un tribunal ou un organisme gouvernemental).",
				},
				legal_requirements: {
					title: "Exigences légales",
					description: "Watch'Us peut communiquer vos Données à Caractère Personnel si elle estime de bonne foi que cela est nécessaire pour :",
					items: [
						"S'acquitter d'une obligation légale",
						"Protéger et défendre les droits ou les biens de la DCF",
						"Prévenir d'éventuels actes répréhensibles ou enquêter sur de tels actes dans le cadre du Site",
						"Assurer la sécurité personnelle des utilisateurs du Site ou du public",
						"Se protéger contre la responsabilité civile",
					],
				},
			},
			data_security: {
				title: "Sécurité des données",
				description:
					"La sécurité de vos données nous tient à cœur. Toutefois, n'oubliez pas qu'aucune méthode de transmission de données par Internet ou méthode de stockage électronique n'est sûre à 100 %. Bien que nous nous efforcions d'utiliser des méthodes commercialement acceptables pour protéger vos Données à Caractère Personnel, nous ne pouvons pas leur garantir une sécurité absolue.",
			},
			user_rights: {
				title: "Droits à la protection des données qui vous sont accordés par le Règlement Général relatif à la Protection des Données (RGPD)",
				description:
					"Si vous résidez dans l'Espace économique européen (EEE), vous avez certains droits en matière de protection des données. La DCF entend prendre des mesures raisonnables pour vous permettre de corriger, de modifier ou de supprimer vos Données à Caractère Personnel ou d'en limiter l'utilisation.",
				contact_info:
					"Si vous souhaitez savoir quelles Données à Caractère Personnel nous détenons à votre sujet et voulez qu'elles soient supprimées de nos systèmes, veuillez nous contacter.",
				rights_list: [
					"Le droit d'accéder aux informations que nous détenons à votre sujet, de les actualiser ou de les supprimer. Lorsque cette option est proposée, vous pouvez consulter ou actualiser vos Données à Caractère Personnel ou en demander la suppression en nous contactant.",
					"Droit de rectification. Vous avez le droit de faire rectifier vos données si elles sont inexactes ou incomplètes.",
					"Droit d'opposition. Vous avez le droit de vous opposer à ce que nous traitions vos Données à Caractère Personnel.",
					"Droit de limitation. Vous avez le droit de nous demander de limiter le traitement de vos données personnelles.",
					"Le droit à la portabilité des données. Vous avez le droit de recevoir une copie des informations que nous détenons à votre sujet dans un format couramment utilisé, structuré et lisible par une machine.",
					"Droit au retrait du consentement. Vous avez également le droit de retirer votre consentement à tout moment si La DCF s'est appuyée sur votre consentement pour traiter vos données personnelles.",
				],
				proof_of_identity: "Veuillez noter que nous pouvons vous demander de prouver votre identité avant de répondre à des demandes de cette nature.",
				complaint:
					"Vous avez le droit de déposer plainte auprès d'une autorité de protection des données au sujet de notre collecte et de notre utilisation de vos Données à Caractère Personnel. Pour plus de précisions, veuillez contacter l'autorité de protection des données la plus proche de vous dans l'Espace économique européen (EEE).",
			},
			service_providers: {
				title: "Prestataires de services",
				description:
					"Nous pouvons faire appel à des sociétés tierces et à des tierces personnes pour faciliter la prestation de notre Site (« Prestataires de Services »), assurer le Site en notre nom, assurer des services liés au Site ou nous aider à analyser la façon dont notre Site est utilisé.",
				access_restriction:
					"Ces tiers n'ont accès à vos Données à Caractère Personnel que pour effectuer ces tâches en notre nom et il leur est interdit de les communiquer ou de les utiliser à quelle qu'autre fin.",
			},
			external_links: {
				title: "Liens pointant vers d'autres sites",
				description:
					"Il se peut que notre Site contienne des liens pointant vers d'autres sites que nous n'exploitons pas. Si vous cliquez sur un lien de tiers, vous serez redirigé vers le site de ce tiers. Nous vous recommandons vivement d'examiner la politique de confidentialité de chacun des sites que vous consultez. Nous n'avons aucun contrôle sur le contenu, les politiques ou pratiques de confidentialité des sites ou services de tiers et déclinons toute responsabilité en ce qui les concerne.",
			},
			policy_updates: {
				title: "Modifications de la présente Politique de Confidentialité",
				description:
					"Nous nous réservons le droit d'actualiser notre Politique de Confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la nouvelle Politique de Confidentialité sur cette page.",
				notification:
					"Avant que la modification ne prenne effet, nous vous informerons en actualisant la « date de prise d'effet » qui figure en haut de la présente Politique de Confidentialité.",
				advice: "Nous vous conseillons de consulter la présente Politique de Confidentialité périodiquement pour prendre connaissance de toute modification. Les modifications apportées à la présente Politique de Confidentialité prennent effet lorsqu'elles sont publiées sur cette page.",
			},
			contact: {
				title: "Nous contacter",
				description:
					"Pour toute question relative à la présente Politique de Confidentialité ou pour toute demande concernant vos Données à Caractères Personnelles, veuillez nous contacter :",
				contact_info: {
					contact_person: "Contact DPO : Sarah Llouch",
					email: "Par courrier électronique : watchus13@gmail.com",
					address: "Par voie postale : 15 avenue Hoche, 75008 Paris",
				},
			},
			legal_notice: {
				title: "Mentions légales",
				description:
					"Ce site est créé par la société Watch'Us SARL au capital de 45 000€ (R.C.S. Paris B 482 201 407) sise au 15 AV HOCHE 75008 PARIS et présidée par Mme Sarah Lelouch. Adresse email : watchus13@gmail.com",
				publication_director: "Directeur de la publication : Mme Sarah Lelouch.",
				hosting_info: {
					company: "Le site est hébergé par OVH",
					details:
						"SAS au capital de 10 174 560 €\nRCS Lille Métropole 424 761 419 00045\nCode APE 2620Z\nN° TVA : FR 22 424 761 419\nSiège social : 2 rue Kellermann - 59100 Roubaix - France",
					subsidiary:
						"OVH SAS est une filiale de la société OVH Groupe SA société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2 rue Kellermann 59100 Roubaix.",
					publication_director: "Directeur de la publication : Michel Paulin",
				},
			},
		},
		privacy_policy: {
			page_title: "Politique de confidentialité",
			title: "Politique de confidentialité",
			subtitle: "Date d'entrée en vigueur : 19 avril 2024",
			foreword: {
				title: "Préambule",
				content:
					"La présente charte sur le respect de la vie privée formalise notre engagement concernant la protection de la vie privée des utilisateurs du site internet ClapAction exploité par Watch'US. Cette charte constitue le cadre contractuel entre l'utilisateur et Watch'US.",
			},
			data_protection: {
				title: "Respect des normes de protection des données",
				content:
					"Nous traitons vos données personnelles en stricte conformité avec le Règlement Général sur la Protection des Données (RGPD) du 27 avril 2016. Nous nous engageons à ne collecter que des données adéquates, pertinentes et limitées aux nécessités des services proposés, sans jamais inclure de données sensibles.",
			},
			collect: {
				title: "Collecte et utilisation des données personnelles",
				content:
					"Vos données sont collectées lors de la navigation sur le site, la création de compte, les transactions financières ou lorsque vous nous contactez. Ces données sont utilisées exclusivement pour la gestion et l'amélioration de nos services, ainsi que pour la communication relative à ces services.",
			},
			data_security: {
				title: "Conservation et sécurité des données",
				content:
					"Nous appliquons des mesures techniques et organisationnelles avancées pour garantir la sécurité et l'intégrité de vos données personnelles. Toutefois, nous rappelons que la sécurité absolue n'est pas garantie sur Internet. Il est de votre responsabilité de sécuriser également vos identifiants et mots de passe.",
			},
			personal_data: {
				title: "Partage des données personnelles",
				content:
					"Nous ne partageons vos données avec des tiers que pour les nécessités de gestion ou pour répondre à des obligations légales. Aucun transfert hors de l'Union Européenne n'est réalisé sans les garanties appropriées telles que les clauses contractuelles types de la Commission européenne.",
			},
			your_rights: {
				title: "Vos droits",
				content:
					"Vous disposez de droits étendus sur vos données personnelles, incluant l'accès, la correction, la suppression ou la limitation de leur traitement. Vous pouvez exercer ces droits en nous contactant directement par les moyens fournis dans cette charte.",
			},
			chart: {
				title: "Modifications de la charte",
				content:
					"Cette politique peut être mise à jour ; nous vous invitons à consulter régulièrement cette page pour vous tenir informé des changements qui pourraient affecter le traitement de vos données personnelles.",
			},
			contact: {
				title: "Contact et délégué à la protection des données",
				content:
					"Pour toutes questions ou pour exercer vos droits, vous pouvez nous contacter via l'email ou l'adresse postale indiquée. Si applicable, les coordonnées de notre Délégué à la Protection des Données sont également fournies.",
			},
			appeal: {
				title: "Recours",
				content: "En cas de litige, vous avez le droit de saisir la Commission Nationale de l'Informatique et des Libertés (CNIL).",
			},
		},
		profile: {
			page_title: "Profil",
			change_avatar: "Changer la photo de profil",
			cancel_button: "Annuler",
			submit_button: "Appliquer les modifications",
			update_success: "Votre modification a été prise en compte.",
			inputs: {
				firstname: {
					label: "Prénom",
					placeholder: "Jhon",
				},
				lastname: {
					label: "Nom",
					placeholder: "Doe",
				},
				email: {
					label: "Email",
					placeholder: "jhondoe@gmail.com",
				},
				phone: {
					label: "Téléphone",
					placeholder: "06 18 24 32 17",
				},
				birthdate: {
					label: "Date de naissance",
					placeholder: "24/05/1996",
				},
				city: {
					label: "Ville",
					placeholder: "Paris",
				},
				address: {
					label: "Adresse",
					placeholder: "18 rue Maréchal-Juin",
				},
				zipCode: {
					label: "Code postal",
					placeholder: "75 001",
				},
				sex: {
					label: "Sexe",
					options: [
						{
							label: "Homme",
							value: "homme",
						},
						{
							label: "Femme",
							value: "femme",
						},
						{
							label: "Autre",
							value: "autre",
						},
					],
				},
				password: {
					label: "Mot de passe",
					placeholder: "johndoemotdepasse@78",
					additional_info: "Au moins 8 caractères dont 1 majuscule, 1 minuscule et 1 chiffre.",
				},
			},
		},
		home: {
			page_title: "Accueil",
			banner: {
				title: "Vos idées prennent vie sur",
				inside_title: "grand écran !",
				subtitle: "Partagez vos idées et votez pour vos projets préférés.",
				discover_project: "Découvrir les projets",
				submit_project: "Déposer un projet",
			},
			why_join: {
				title: "Pourquoi rejoindre",
				inside_title: "clapAction ?",
				content:
					"Bienvenue sur clapAction, ici vos idées prennent vie grâce à la puissance de l'IA et à la sécurité inégalée de la blockchain. Que vous soyez un créateur passionné ou un fervent supporter de projets innovants, clapAction vous offre une expérience unique et sécurisée.",
				button: "Créer un compte",
				creator_title: "Créateurs",
				creator_content: "Protégez vos idées grâce à la technologie blockchain et, si vous le souhaitez, partagez-les avec des professionnels du cinéma.",
				public_title: "Public",
				public_content: "Exprimez votre soutien en votant pour les projets qui vous inspirent.",
			},
			marketplace: {
				title: "Bientôt : achetez parmi une collection authentique",
				inside_title: "d'objets cinématographiques certifiés",
				subtitle: "Restez connectés pour être parmi les premiers à explorer notre boutique.",
				explore_title: "Explorez notre boutique",
				explore_content: "Plongez dans notre marketplace dédiée aux passionnés de cinéma.",
				certified_title: "Objets certifiés",
				certified_content: "Une large sélection d'objets authentiques et certifiés sur la blockchain.",
			},
			featured_projects: {
				title: "Projets en",
				inside_title: "développement",
				subtitle: "Explorez les récits captivants et les univers uniques des projets accompagnés par clapAction.",
				discover_project: "Découvrir les projets",
			},
			storytelling: {
				first_block: {
					title: "Passionnés : Participez à la",
					inside_title: "création",
					title_2: "et à la",
					inside_title_2: "production des films",
					subtitle:
						"Votez pour contribuer étape par étape à la production des films : du développement (écriture, casting...) à la sortie en salles, en passant par le tournage et la post-production. Interagissez avec la communauté, et bénéficiez d'avantages exclusifs : accès à des avant-premières, à des tournages, et à vos artistes préférés.",
				},
				second_block: {
					title: "Partagez vos idées",
					inside_title: "en toute sécurité",
					subtitle:
						"Vos documents sont ancrés sur la blockchain, ce qui garantit la préservation et la sécurité intégrale de votre création. Que vous soyez professionnels ou amateurs, partagez vos idées avec l'assurance  qu'elles seront soigneusement protégées à vie.",
					additionnal_subtitle: "NB : vous pouvez choisir de protéger vos idées et de les garder confidentielles, ou de les soumettre à notre comité de sélection.",
				},
				third_block: {
					title: "Professionnels : Consultez",
					inside_title: "l'avis du public",
					title_2: "à tout moment",
					subtitle:
						"Créez une communauté autour de vos projets, accédez à un baromètre d'opinion, soumettez vos productions aux votes du public pour vous orienter dans votre développement.",
				},
			},
			achivement: {
				title: "Notre",
				inside_title: "réalisation phare",
				subtitle: "Celle qui n'avait pas vu Friends",
				content:
					"Greg retrouve sa future femme Rachel quelques minutes avant la cérémonie. Elle est si belle, il a envie de dire « Oh mon Dieu ! » en référence à un personnage dans la série Friends. Rachel ne comprend pas sa blague, elle n'a jamais vu la série. Greg est choqué, il ne veut plus se marier.",
			},
			FAQ: {
				title: "Questions fréquemment posées",
				questions: [
					{
						title: "Comment puis-je soumettre mon scénario ?",
						content: "Créez un compte, téléchargez votre PDF et soumettez-le à la communauté pour vote et discussion.",
					},
					{
						title: "Quels sont les critères de sélection des projets ?",
						content: "Les projets sont sélectionnés pour le vote sur la base de leur originalité, de leur faisabilité et de leur potentiel cinématographique.",
					},
					{
						title: "Comment puis-je voter pour un projet ?",
						content: "Connectez-vous à votre compte, naviguez vers la page des projets et votez via les options disponibles.",
					},
				],
			},
		},
		confirmation_code: {
			page_title: "Confirmation du code",
			title: "Code d'authentification",
			subtitle: "Entrez le code OTP que nous venons de vous envoyer sur votre adresse e-mail enregistré",
			button: "Se connecter",
			send_code: "Envoyer un nouveau code",
		},
		my_projects: {
			page_title: "Mes projets déposés",
			title: "Mes projets déposés",
			filters: {
				all_projects: "Tous les projets",
				accepted: "Validé",
				refused: "Refusé",
				studying: "En cours d'étude",
				unstudied: "Non étudié",
			},
			table: {
				headers: {
					project_name: "Nom du projet",
					ai_image: "Image IA",
					anchor_proof: "Preuve d'ancrage",
					status: "Status",
					date: "Date",
				},
				waiting: "En attente...",
				see_image: "Voir l'image",
				anchor_link: "Lien d'ancrage",
				download: "Télécharger",
			},
		},
		submit_project: {
			page_title: "Déposer un projet",
			title: "Déposer un projet",
			subtitle:
				"Partagez en toute sécurité vos idées en remplissant les champs ci-dessous. Une Intelligence Artificielle générera automatiquement une affiche de film correspondant à votre idée, accompagnant ainsi votre projet. Cette affiche sera visible exclusivement par vous.",
			labels: {
				project_title: "Titre du projet",
				project_title_placeholder: "100 caractères maximum",
				project_pitch: "Pitch",
				project_format: "Format",
				project_reason: "Quelle est la raison principale pour laquelle vous déposez votre projet ?",
				project_pitch_additionnal:
					"Veuillez fournir une description concise et attrayante. Cette description sera utilisée pour mettre en avant votre projet sur la plateforme.",
				project_pitch_placeholder: "Pitch (150 caractères minimum)",
				cv: "CV",
				scenario: "Synopsis et/ou éléments artistiques",
			},
			selections: {
				format: {
					long_metrage: "Long métrage",
					court_metrage: "Court métrage",
					serie: "Série",
					documentaire: "Documentaire",
				},
				reason: {
					protect: "Je dépose mon projet simplement pour le protéger",
					protect_and_development_aid: "Je dépose mon projet pour le protéger et le soumettre à l'aide au développement",
				},
			},
			drag_and_drop: {
				cv: "Glisser ou cliquer ici pour télécharger le CV",
				scenario: "Glisser ou cliquer ici pour télécharger votre document",
			},
			confidentiality:
				"NB : En déposant votre projet sur clapAction, le scénario sera protégé sur la blockchain. Si vous souhaitez que votre projet soit étudié par le Comité de Sélection, nous vous invitons à ne pas dépasser les 2 pages dactylographiées.",
			send_button: "Déposer le projet",
		},
		contact: {
			page_title: "Contact",
			title: "Contactez-nous",
			subtitle: "Besoin d'un renseignement ? Envoyez-nous vos questions ou commentaires, nous vous répondrons rapidement !",
			labels: {
				subject: "Sujet",
				email: "Email",
				message: "Message",
				firstname: "Prénom",
				lastname: "Nom",
				phone: "Téléphone",
			},
			confirmation: "Merci pour votre message, nous vous répondrons dans les plus brefs délais.",
			button: "Envoyer",
		},
		subscribe: {
			page_title: "Abonnez-vous",
			title: "Apprenez à connaître votre public et",
			inside_title: "découvrez ses attentes !",
			box: "Vous êtes un professionnel ?",
			subtitle: "Obtenez des insights précieux sur votre public et influencez l'industrie",
			description: `Vous développez des films ? Créez une communauté autour de vos projets avec clapAction ! Découvrez les attentes du public, impliquez-le dans votre processus de développement et de production, jusqu'à la sortie de votre film. Abonnez-vous dès maintenant ! Dès le mois d'octobre, vous pourrez partager en exclusivité, les projets qui vous animent aujourd'hui pour en faire les succès de demain. L'attente est l'écrin de la curiosité et du bonheur... clapAction en est un.`,
			use_data: "Utilisez les données du public pour influencer vos décisions et celles de l'industrie !",
			transform: "Transformez votre projet en succès dès sa phase de développement !",
			radio: {
				title: "Vous souhaitez",
				options: {
					build: "Partager un film, construire et interagir avec sa communauté et apprendre à la connaître",
					access: "Partager plusieurs films et avoir accès à toutes les données et à toutes les attentes du public",
				},
			},
			labels: {
				firstname: "Prénom",
				lastname: "Nom",
				address: "Adresse",
				phone: "Téléphone",
				email: "Email",
				message: "Vous avez une question, envoyez-nous un message",
			},
			confirmation: "Merci pour votre message, nous vous répondrons dans les plus brefs délais.",
			button: "Envoyer",
		},
		dashboard: {
			page_title: "Tableau de bord",
		},
		reset_password: {
			page_title: "Réinitialisation du mot de passe",
			title: {
				first: "Réinitialisation du",
				second: "mot de passe",
			},
			subtitle: "Veuillez saisir un nouveau mot de passe",
			labels: {
				password: "Mot de passe",
				password_additionnal: "Au moins 8 caractères",
				password_confirm: "Confirmer le mot de passe",
			},
			reset_button: "Changer de mot de passe",
		},
		legal_notice: {
			page_title: "Mentions légales",
			title: "Mentions légales",
			subtitle:
				"Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site clapAction l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.",
			edition: {
				title: "Edition du site",
				url: "Le présent site, accessible à l'URL https://clapaction.app/ (le « Site »), est édité par :",
				company:
					"Watch'Us, société au capital de 45000 euros, inscrite au R.C.S. de PARIS sous le numéro 482 201 407 R.C.S. Paris, dont le siège social est situé au 15 AVENUE HOCHE, 75008 PARIS, représenté(e) par Sarah Lelouch dûment habilité(e)",
				tva: "Le numéro individuel TVA de l'éditeur est : FR68482201407.",
			},
			hosting: {
				title: "Hébergement",
				hosted: "Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).",
			},
			publication: {
				title: "Directeur de publication",
				content: "Le Directeur de la publication du Site est Sarah Lelouch.",
			},
			contact: {
				title: "Nous contacter",
				mail: "Par email : contact@clapaction.com",
				letter: "Par courrier : 15 AVENUE HOCHE, 75008 PARIS",
				legal: "Génération des mentions légales par Legalstart",
			},
		},
		forgot_password: {
			page_title: "Mot de passe oublié",
			title: "Mot de passe oublié ?",
			labels: {
				email: "Email",
				email_additionnal: "*Nous allons envoyer un lien par mail pour réinitialiser votre mot de passe",
			},
			send_button: "Envoyer le lien par mail",
			redirect_link: "Retour",
			confirmation: "Un email vous a été envoyé avec un code de réinitialisation de Password.",
		},
		login: {
			page_title: "Se connecter",
			title: "Se connecter",
			subtitle: "",
			labels: {
				email: "Email",
				password: "Mot de passe",
			},
			password_forgotten: "Mot de passe oublié ?",
			login_button: "Se connecter",
			redirect_text: "Vous n'avez pas de compte ?",
			redirect_link: "S'inscrire",
		},
		projects: {
			page_title: "Les projets",
			title: "Découvrez les films et séries sélectionnés par",
			inside_title: "notre Comité",
		},
		register: {
			page_title: "S'incrire",
			title: "S'inscrire",
			subtitle:
				"Rejoignez clapAction : proposez vos idées de films ou de séries en toute confiance, et participez à la création des projets de clapAction. Tous les champs ci-dessous sont obligatoires.",
			labels: {
				firstname: "Prénom",
				lastname: "Nom",
				pseudo: "Pseudo",
				email: "Email",
				password: "Mot de passe",
				password_confirmation: "Confirmer le mot de passe",
				password_additionnal: "Au moins 8 caractères",
				checkbox: "J'accepte les conditions d'utilisation",
				zipcode: "Code postal",
				city: "Ville",
				address: "Adresse",
				phoneNumber: "Téléphone",
			},
			register_button: "S'inscrire",
			redirect_text: "Vous avez déjà un compte ?",
			redirect_link: "Se connecter",
		},
	},
	component: {
		theme_selector: {
			title: "Genre",
			select: "Sélectionner le genre correspondant",
			themes: {
				comedie: "Comédie",
				comedie_romantique: "Comédie romantique",
				comedie_dramatique: "Comédie dramatique",
				biopic: "Biopic",
				thriller: "Thriller",
				film_daction: "Film d'action",
				film_policier: "Film policier",
				film_dhorreur: "Film d'horreur",
				film_historique: "Film historique",
				science_fiction: "Science fiction",
				film_danimation: "Film d'animation",
				comedie_musicale: "Comédie musicale",
				dramédie: "Dramédie",
				comedie_absurde: "Comédie absurde",
				thriller_psychologique: "Thriller psychologique",
			},
		},
		featured_project: {
			published_at: "Publication : le {{var1}}",
			category: "Genre :",
			length: "Format :",
			button: "Voir le projet",
		},
		drag_and_drop_file: {
			no_file_selected: "Aucun fichier sélectionné",
		},
		authpagetemplate: {
			footer: {
				copyright: "© 2024 clapAction",
			},
		},
		lang_selector: {
			fr: "FR",
			en: "EN",
		},
		header: {
			login: "Se connecter",
			register: "S'inscrire",
			submit_project: "Déposer un projet",
			my_projects: "Mes projets déposés",
			personnal_informations: "Informations personnelles",
			home: "Accueil",
			marketplace: "Marketplace",
			projects: "Les projets",
			contact: "Contact",
			subscribe: "Espace professionnel",
			all_projects: "Tous les projets",
			logout: "Se déconnecter",
		},
	},
	enums: {
		EValidationErrors: EValidationErrorImplementation,
		EProjectThemes: EProjectThemeImplementation,
		EProjectFormats: EProjectFormatImplementation,
		EProjectReasons: EProjectReasonImplementation,
	},
};

export default trads;
