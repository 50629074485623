import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import StorytellingDesignOne from "assets/images/storytelling-design-1.png";
import StorytellingDesignTwo from "assets/images/storytelling-design-2.png";
import StorytellingDesignThree from "assets/images/storytelling-design-3.png";

export default function Storytelling() {
	return (
		<section className={classes["root"]}>
			<div className={classes["block"]}>
				<div className={classes["content"]}>
					<Typography type="h2" weight="bold">
						{I18n.trslt(I18n.asset.pages.home.storytelling.second_block.title)}
						<Typography type="span" weight="bold" color="primary" className={classes["inside-title"]}>
							&nbsp;{I18n.trslt(I18n.asset.pages.home.storytelling.second_block.inside_title)}
						</Typography>
					</Typography>

					<Typography type="p" size="large" weight="regular">
						{I18n.trslt(I18n.asset.pages.home.storytelling.second_block.subtitle)}
					</Typography>
					<Typography type="p" size="xsmall" weight="regular">
						{I18n.trslt(I18n.asset.pages.home.storytelling.second_block.additionnal_subtitle)}
					</Typography>
				</div>

				<div className={classes["design-container"]}>
					<img src={StorytellingDesignTwo} alt="Storytelling Design Two" className={classes["image"]} />
				</div>
			</div>

			<div className={classes["block"]}>
				<div className={classes["design-container"]}>
					<img src={StorytellingDesignOne} alt="Storytelling Design One" className={classes["image"]} />
				</div>

				<div className={classes["content"]}>
					<Typography type="h2" weight="bold">
						{I18n.trslt(I18n.asset.pages.home.storytelling.first_block.title)}
						<Typography type="span" weight="bold" color="secondary" className={classes["inside-title"]}>
							&nbsp;{I18n.trslt(I18n.asset.pages.home.storytelling.first_block.inside_title)}
						</Typography>
						&nbsp;{I18n.trslt(I18n.asset.pages.home.storytelling.first_block.title_2)}
						<Typography type="span" weight="bold" color="secondary" className={classes["inside-title"]}>
							&nbsp;{I18n.trslt(I18n.asset.pages.home.storytelling.first_block.inside_title_2)}
						</Typography>
					</Typography>

					<Typography type="p" size="large" weight="regular">
						{I18n.trslt(I18n.asset.pages.home.storytelling.first_block.subtitle)}
					</Typography>
				</div>
			</div>

			<div className={classes["block"]}>
				<div className={classes["content"]}>
					<Typography type="h2" weight="bold">
						{I18n.trslt(I18n.asset.pages.home.storytelling.third_block.title)}
						<Typography type="span" weight="bold" color="secondary" className={classes["inside-title"]}>
							&nbsp;{I18n.trslt(I18n.asset.pages.home.storytelling.third_block.inside_title)}
						</Typography>
						&nbsp;{I18n.trslt(I18n.asset.pages.home.storytelling.third_block.title_2)}
					</Typography>

					<Typography type="p" size="large" weight="regular">
						{I18n.trslt(I18n.asset.pages.home.storytelling.third_block.subtitle)}
					</Typography>
				</div>

				<div className={classes["design-container"]}>
					<img src={StorytellingDesignThree} alt="Storytelling Design Three" className={classes["image"]} />
				</div>
			</div>
		</section>
	);
}
