import ProjectApi from "api/clapaction/ProjectApi";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import ProjectCreateRequestResource from "common/resources/Project/ProjectCreateRequestResource";
import ProjectReponseResource from "common/resources/Project/ProjectResponseResource";
import ProjectUpdateStatusRequestResource from "common/resources/Project/ProjectUpdateStatusRequestResource";
import QuestionResponseResource from "common/resources/Question/QuestionResponseResource";
import ProjectServiceClassToken from "common/resources/injectables/ProjectServiceClassToken";
import { container, singleton } from "tsyringe";
import { Pagination } from "utils/Pagination";
import toValidationError from "utils/toValidationError";

@singleton()
export default class ProjectService implements ProjectServiceClassToken {
	private projectApi: ProjectApi = container.resolve(ProjectApi);

	public async exists(_id: string): Promise<boolean> {
		return this.getProjectById(_id)
			.then(() => true)
			.catch(() => false);
	}

	public async getProjects(): Promise<ProjectReponseResource[]> {
		return this.projectApi.getProjects();
	}

	public async getProjectQuestions(id: string): Promise<QuestionResponseResource[]> {
		return this.projectApi.getProjectQuestions(id);
	}

	public async getProjectById(id: string): Promise<ProjectReponseResource> {
		return this.projectApi.getProjectById(id);
	}

	public async createProject(project: ProjectCreateRequestResource, files: { scenario?: File; cv?: File }): Promise<ProjectReponseResource> {
		return this.projectApi.createProject(project, files).catch(toValidationError);
	}

	public async getMyProjects(pagination?: Pagination): Promise<ProjectReponseResource[]> {
		return this.projectApi.getMyProjects(pagination);
	}

	public async countMyProjects():Promise<number> {
		return this.projectApi.countMyProjects().then((res) => res.total).catch(toValidationError);
	}
}
