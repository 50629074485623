import PageTemplate from "components/PageTemplate";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import DefaultAvatar from "assets/images/default-avatar.svg";
import Typography from "components/elements/Typography";
import LazyImage from "components/elements/LazyImage";
import { match } from "ts-pattern";
import Social from "components/materials/SocialButton";
import { useState } from "react";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";

type IProps = {
	project: ProjectResponseResource | null;
};

function getProjectCreatedAt(date: Date | null | undefined) {
	const newDate = new Date(date ?? "").toLocaleDateString("fr-FR", { day: "numeric", month: "long", year: "numeric" });
	return newDate;
}

const maxPitchLength = 200;

export default function GenericProjectTemplate(props: IProps) {
	const [isPitchVisible, setIsPitchVisible] = useState<boolean>(false);

	if (!props.project) {
		return null;
	}
	const author = `${props.project.author.firstName} ${props.project.author.lastName}`;
	const createdAt = getProjectCreatedAt(props.project.createdAt);
	const image = props.project.poster?.file?.url ?? "";
	const theme = I18n.trslt(I18n.asset.enums.EProjectThemes[props.project.theme]);
	const format = I18n.trslt(I18n.asset.enums.EProjectFormats[props.project.format]);

	const getPitch = () => {
		const pitch = match(I18n.getLang())
			.with("fr", () => props.project?.pitch)
			.otherwise(() => props.project?.pitch_en);

		if (!pitch) return "";
		if (pitch.length > maxPitchLength && !isPitchVisible) {
			return pitch.slice(0, maxPitchLength) + "...";
		}
		return pitch;
	};

	const tooglePitchIsVisible = () => {
		setIsPitchVisible(!isPitchVisible);
	};

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.project.page_title)} mainSectionClassName={classes["root"]}>
			<div className={classes["header-container"]}>
				<div className={classes["content-container"]}>
					<div className={classes["header"]}>
						<div className={classes["author"]}>
							<img src={props.project.author.avatar?.url ?? DefaultAvatar} alt="Author" className={classes["avatar"]} />
							<Typography type="p" weight="bold" size="small">
								{author}
							</Typography>
						</div>
						<div className={classes["infos"]}>
							<Typography type="p" weight="medium" size="small" className={classes["info"]}>
								{I18n.trslt(I18n.asset.pages.project.publication)}
								{createdAt}
							</Typography>
							<Typography type="p" weight="medium" size="small" className={classes["info"]}>
								{I18n.trslt(I18n.asset.pages.project.genre)}
								{theme}
							</Typography>
							<Typography type="p" weight="medium" size="small" className={classes["info"]}>
								{I18n.trslt(I18n.asset.pages.project.format)}
								{format}
							</Typography>
						</div>
					</div>

					<div className={classes["content"]}>
						<Typography type="h1" weight="bold">
							{match(I18n.getLang())
								.with("fr", () => props.project?.title)
								.otherwise(() => props.project?.title_en)}
						</Typography>
						<Typography type="p" weight="regular" size="medium">
							{getPitch()}
						</Typography>
						<Button variant={EButtonVariant.OUTLINED} color={EButtonColor.PRIMARY} onClick={tooglePitchIsVisible}>
							{isPitchVisible && I18n.trslt(I18n.asset.pages.project.buttons.readLess)}
							{!isPitchVisible && I18n.trslt(I18n.asset.pages.project.buttons.readMore)}
						</Button>
					</div>
					<Social project={props.project} />
				</div>

				<div className={classes["poster-container"]}>
					<LazyImage src={image} alt="Poster" className={classes["poster"]} />
				</div>
			</div>
		</PageTemplate>
	);
}
