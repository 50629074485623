import AuthPageTemplate from "components/PageTemplate/AuthPageTemplate";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Typography from "components/elements/Typography";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import Form from "components/materials/Form";
import { useCallback, useContext, useEffect, useState } from "react";
import { ValidationError } from "common/resources/Resource";
import UserValidatePinCodeRequestResource from "common/resources/User/UserValidatePinCodeRequestResource";
import ConfirmationCodeInput from "components/materials/Form/ConfirmationCodeInputElement";
import { container } from "tsyringe";
import AuthService from "services/AuthService";
import { UserContext } from "contexts/User";
import { useNavigate } from "react-router-dom";
import ModuleConfig from "configs/ModuleConfig";
import UserSendPinRequestResource from "common/resources/User/UserSendPinRequestResource";

const modules = container.resolve(ModuleConfig).get().modules;
const authService = container.resolve(AuthService);

/**
 * Convert seconds to HH:MM:SS format
 */
function toHMS(seconds: number) {
	function pad(n: number) {
		return (n < 10 ? "0" : "") + n;
	}

	const hh = pad(Math.floor(seconds / 3600));
	const mm = pad(Math.floor((seconds % 3600) / 60));
	const ss = pad(Math.floor(seconds % 60));

	return `${hh}:${mm}:${ss}`;
}

/**
 * Return the remaining seconds from a date until now
 */
function remainingSeconds(from: Date, now: Date): number {
	return Math.max(Math.floor(from.getTime() - now.getTime()) / 1000, 0);
}

export default function ConfirmationCode() {
	const userContext = useContext(UserContext);
	const [canResendStartDate, setCanResendStartDate] = useState<Date | null>(null);
	const [canResendIn, setCanResendIn] = useState<number | null>(null);
	const navigate = useNavigate();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);

			if (!userContext.user) {
				navigate(modules.pages.Home.props.path);
				return;
			}

			const confirmationCode0 = formData["confirmation-code-0"] as string;
			const confirmationCode1 = formData["confirmation-code-1"] as string;
			const confirmationCode2 = formData["confirmation-code-2"] as string;
			const confirmationCode3 = formData["confirmation-code-3"] as string;
			const confirmationCode4 = formData["confirmation-code-4"] as string;

			const confirmationCode = confirmationCode0 + confirmationCode1 + confirmationCode2 + confirmationCode3 + confirmationCode4;

			authService
				.confirmRegistration(UserValidatePinCodeRequestResource.hydrate<UserValidatePinCodeRequestResource>({ email: userContext.user.email, pinCode: confirmationCode }))
				.then(() => {
					navigate(modules.pages.Home.props.path);
				})
				.catch((error) => {
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[navigate, userContext.user],
	);

	/**
	 * Update the remaining time before the user can resend the confirmation email
	 */
	const updateCanResendIn = useCallback(
		(interval: NodeJS.Timeout) => {
			if (!canResendStartDate) return;

			const seconds = remainingSeconds(canResendStartDate, new Date());
			setCanResendIn(seconds);

			if (seconds === 0) clearInterval(interval);
		},
		[canResendStartDate],
	);

	/**
	 * Update the date when the user can resend the confirmation email
	 */
	const updateCanResendStartDate = useCallback(() => {
		authService.canResendPinCode().then(({ startTime }) => {
			setCanResendStartDate(startTime);
		});
	}, []);

	/**
	 * Resend the confirmation email and update the can resend start date
	 */
	const resendConfirmationEmail = useCallback(() => {
		const email = userContext.user?.email;

		if (!email) return;

		authService.resendConfirmationEmail(UserSendPinRequestResource.hydrate<UserSendPinRequestResource>({ email: userContext.user!.email, lang: I18n.getLang() })).then(() => {
			updateCanResendStartDate();
		});
	}, [updateCanResendStartDate, userContext.user]);

	/**
	 * Initial fetch of can resend start date
	 */
	useEffect(() => {
		updateCanResendStartDate();
	}, [updateCanResendStartDate]);

	/**
	 * Periodic update of remaining time before the user can resend the confirmation email
	 */
	useEffect(() => {
		if (!canResendStartDate) return;

		const interval = setInterval(() => {
			updateCanResendIn(interval);
		}, 1000);

		return () => clearInterval(interval);
	}, [canResendStartDate, updateCanResendIn]);

	return (
		<AuthPageTemplate tabTitle={I18n.trslt(I18n.asset.pages.confirmation_code.page_title)}>
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<Typography type="h1" weight="bold">
						{I18n.trslt(I18n.asset.pages.confirmation_code.title)}
					</Typography>
					<Typography type="p" size="large" weight="regular">
						{I18n.trslt(I18n.asset.pages.confirmation_code.subtitle)}
					</Typography>
				</div>

				<Form onSubmit={onSubmit} errors={errors} className={classes["content"]}>
					<ConfirmationCodeInput name="confirmation-code" className={classes["confirmation-code"]} />

					<div className={classes["button-container"]}>
						<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} type="submit" fullwidth>
							{I18n.trslt(I18n.asset.pages.confirmation_code.button)}
						</Button>
						{canResendIn === 0 && (
							<Button
								variant={EButtonVariant.OUTLINED}
								color={EButtonColor.PRIMARY}
								size={EButtonSize.LARGE}
								type="button"
								fullwidth
								onClick={resendConfirmationEmail}>
								{I18n.trslt(I18n.asset.pages.confirmation_code.send_code)}
							</Button>
						)}
						{!!canResendIn && canResendIn > 0 && (
							<Button
								variant={EButtonVariant.OUTLINED}
								color={EButtonColor.PRIMARY}
								size={EButtonSize.LARGE}
								type="button"
								fullwidth
								disabled
								onClick={resendConfirmationEmail}>
								{I18n.trslt(I18n.asset.pages.confirmation_code.send_code)} in {toHMS(canResendIn)}
							</Button>
						)}
					</div>
				</Form>
			</div>
		</AuthPageTemplate>
	);
}
