import FileApi from "api/clapaction/admin/FileApi";
import { container, singleton } from "tsyringe";

@singleton()
export default class FileService {
	private api: FileApi = container.resolve(FileApi);

	public getFileUrl(folder: string, fileId: string): string {
		return this.api.getFileUrl(folder, fileId);
	}
}
