import classNames from "classnames";
import React, { ReactNode } from "react";
import classes from "./classes.module.scss";
import Loader from "../Loader";

export enum EButtonVariant {
	CONTAINED = "contained",
	OUTLINED = "outlined",
	TEXT = "text",
	SELECTOR = "selector",
}

export enum EButtonColor {
	PRIMARY = "primary",
	NEUTRAL = "neutral",
	DESTRUCTIVE = "destructive",
	SECONDARY = "secondary",
}

export enum EButtonSize {
	LARGE = "large",
	MEDIUM = "medium",
	SMALL = "small",
	XSMALL = "xsmall",
}

type IProps = {
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
	variant?: EButtonVariant;
	size?: EButtonSize;
	color?: EButtonColor;
	fullwidth?: boolean;
	disabled?: boolean;
	children?: ReactNode;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	isLoading?: boolean;
	type?: "button" | "submit" | "reset";
	className?: string;
	isSelected?: boolean;
};

export default function Button(props: IProps) {
	const buttonClassNames = classNames([
		classes["root"],
		classes[props.variant ?? EButtonVariant.CONTAINED],
		classes[props.size ?? EButtonSize.MEDIUM],
		classes[props.color ?? EButtonColor.NEUTRAL],
		{
			[classes["selected"]!]: props.isSelected,
		},
		{
			[classes["fullwidth"]!]: props.fullwidth,
		},
		props.className,
	]);

	return (
		<button onClick={props.onClick} disabled={props.disabled || props.isLoading} type={props.type ?? "button"} className={buttonClassNames}>
			{!props.isLoading && (
				<div className={classes["content"]}>
					{props.startIcon && props.startIcon}
					{props.children}
					{props.endIcon && props.endIcon}
				</div>
			)}
			{props.isLoading && (
				<div className={classes["loader-container"]}>
					<Loader className={classes["loader"]} />
				</div>
			)}
		</button>
	);
}
