import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { useCallback, useState } from "react";

type IProps = {
	title: string;
	content: string;
	className?: string;
};

export default function Dropdown(props: IProps) {
	const [isOpen, setIsOpen] = useState(true);

	const onClick = useCallback(() => {
		setIsOpen((prev) => !prev);
	}, []);

	return (
		<div className={classNames(classes["root"], props.className)} onClick={onClick}>
			<div className={classes["header"]}>
				<Typography type="h4" weight="medium">
					{props.title}
				</Typography>

				<ChevronUpIcon
					className={classNames(classes["icon"], {
						[classes["open"]!]: isOpen,
					})}
				/>
			</div>
			<div
				className={classNames(classes["content"], {
					[classes["open"]!]: isOpen,
				})}>
				<Typography type="p" size="medium" weight="regular">
					{props.content}
				</Typography>
			</div>
		</div>
	);
}
