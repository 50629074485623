import classes from "./classes.module.scss";
import {ReactComponent as Studying} from "assets/images/Icons/studying.svg";
import {ReactComponent as Accepted} from "assets/images/Icons/accepted.svg";
import {ReactComponent as Refused} from "assets/images/Icons/refused.svg";
import {ReactComponent as Unstudied} from "assets/images/Icons/unstudied.svg";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import Typography from "components/elements/Typography";
import I18n from "../I18n";




type IProps = {
	status: EProjectStatus;
    withDescription?: boolean;
};

export default function StatusIcon(props: IProps) {
    const getIcon = () => {
        switch(props.status) {
            case EProjectStatus.STUDYING:
                return <Studying />;
            case EProjectStatus.ACCEPTED:
                return <Accepted />;
            case EProjectStatus.REFUSED:
                return <Refused />;
            case EProjectStatus.UNSTUDIED:
                return <Unstudied />;
            default:
                return <Studying />;
        }
    }

    const getStatusTranslation = () => {
        switch (props.status) {
            case EProjectStatus.STUDYING:
                return I18n.trslt(I18n.asset.pages.my_projects.filters.studying);
            case EProjectStatus.ACCEPTED:
                return I18n.trslt(I18n.asset.pages.my_projects.filters.accepted);
            case EProjectStatus.REFUSED:
                return I18n.trslt(I18n.asset.pages.my_projects.filters.refused);
            case EProjectStatus.UNSTUDIED:
                return I18n.trslt(I18n.asset.pages.my_projects.filters.unstudied);
            default:
                return I18n.trslt(I18n.asset.pages.my_projects.filters.studying);
        }
    }
    return (
        <div className={classes["root"]}>
            {getIcon()}
            {props.withDescription && <Typography>{getStatusTranslation()}</Typography>}
        </div>
    );
}