"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.strEnum = void 0;
function strEnum(keys) {
    return keys.reduce((res, key) => {
        res[key] = key;
        return res;
    }, Object.create(null));
}
exports.strEnum = strEnum;
