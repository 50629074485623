import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	children?: React.ReactNode;
};

export default function Chip(props: IProps) {
	return (
		<div className={classes["root"]}>
			{props.children}
		</div>
	);
}
