"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EValidationError = void 0;
exports.EValidationError = {
    isInt: "isInt",
    min: "min",
    max: "max",
    isNumber: "isNumber",
    isEnum: "isEnum",
    isObject: "isObject",
    isArray: "isArray",
    isBoolean: "isBoolean",
    isNotEmpty: "isNotEmpty",
    isString: "isString",
    isPositive: "isPositive",
    maxLength: "maxLength",
    isEmail: "isEmail",
    isNumberString: "isNumberString",
    arrayNotEmpty: "arrayNotEmpty",
    pinCodeNotValid: "pinCodeNotValid",
    badCredentials: "badCredentials",
    wrongPassword: "wrongPassword",
    passwordNotMatch: "passwordNotMatch",
    isStrongPassword: "isStrongPassword",
    uniqueEmail: "uniqueEmail",
    uniqueSha256: "uniqueSha256",
    match: "match",
    fileNotExists: "fileNotExists",
    minLength: "minLength",
    emailNotFound: "emailNotFound",
    anchorNotExists: "anchorNotExists",
    emailCaseSensitive: "emailCaseSensitive",
    emailNotExists: "emailNotExists",
    choiceNotExists: "choiceNotExists",
    mustBeInThePast: "mustBeInThePast",
    invalidPhoneNumber: "invalidPhoneNumber",
    isUrl: "isUrl",
};
