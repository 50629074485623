import { UserContext } from "contexts/User";
import { useContext, useEffect } from "react";

export type IProps = {
	/**
	 * The rules to check, if the user has any of these rules, the component will be displayed
	 */
	children: React.ReactNode;
	/**
	 * The component to display when the user is not logged
	 */
	onNotLoggedComponent?: React.ReactNode | undefined;
	/**
	 * The callback to call when the user is not logged
	 */
	onNotLogged?: () => Promise<void> | void | undefined;
	/**
	 * The component to display when the rule is loading
	 */
	onLoadingComponent?: React.ReactNode | undefined;
	/**
	 * The callback to call when the rule is loading
	 */
	onLoading?: (isLoading: boolean) => Promise<void> | void | undefined;
};

/**
 * This component is used to check if the user is logged in or not.
 */
export default function IsLogged({ onNotLogged, onNotLoggedComponent, onLoading, onLoadingComponent, children }: IProps) {
	const { user, isLoading } = useContext(UserContext);

	/**
	 * Call the onLoading callback when the loading state changes
	 */
	useEffect(() => {
		onLoading?.(isLoading);
	}, [isLoading, onLoading]);

	/**
	 * Call the onNotLogged callback when the user is not logged
	 */
	useEffect(() => {
		if (isLoading) return;
		if (!user) onNotLogged?.();
	}, [onNotLogged, isLoading, user]);

	// If the user is loading, we return the loading component
	if (isLoading) return onLoadingComponent;

	// If the user is not logged, we return the no rules component
	if (!user) return onNotLoggedComponent;

	// If the user is logged, we return the default children
	return <>{children}</>;
}
