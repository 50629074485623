import useHead from "hooks/useHead";
import { Link } from "react-router-dom";

export default function Page404() {
  useHead({
    title: "404",
  });

  return (
    <>
      404 <Link to="/">Go home</Link>
    </>
  );
}