"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationError = void 0;
require("reflect-metadata");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
Object.defineProperty(exports, "ValidationError", { enumerable: true, get: function () { return class_validator_1.ValidationError; } });
class Resource {
    static hydrate(from, options) {
        const self = new this();
        return (0, class_transformer_1.plainToClassFromExist)(self, from, { strategy: "excludeAll", ...options });
    }
    static hydratePartial(from, options) {
        const self = new this();
        return (0, class_transformer_1.plainToClassFromExist)(self, from, { strategy: "excludeAll", ...options });
    }
    static hydrateArray(fromArray, options) {
        return fromArray.map((from) => this.hydrate(from, options));
    }
    static hydratePartialArray(fromArray, options) {
        return fromArray.map((from) => this.hydratePartial(from, options));
    }
    validateOrReject(validatorOptions) {
        const validationError = (0, class_validator_1.validateSync)(this, validatorOptions);
        if (validationError.length)
            return Promise.reject(validationError);
        return (0, class_validator_1.validateOrReject)(this, validatorOptions).then(() => this);
    }
    static validateOrReject(object, validatorOptions) {
        const validationError = (0, class_validator_1.validateSync)(object, validatorOptions);
        if (validationError.length)
            return Promise.reject(validationError);
        return (0, class_validator_1.validateOrReject)(object, validatorOptions).then(() => object);
    }
    static async validateArrayOrReject(objects, validatorOptions) {
        const allSettled = await Promise.allSettled(objects.map(async (object) => object.validateOrReject?.(validatorOptions)));
        const rejected = allSettled.filter((result) => result.status === "rejected");
        if (rejected.length)
            return Promise.reject(rejected);
        return Promise.resolve();
    }
}
exports.default = Resource;
